import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import en from './en.json';

i18n.use(initReactI18next).init({
  // for all options read: https://www.i18next.com/overview/configuration-options
  fallbackLng: 'en',
  interpolation: { escapeValue: false }, // not needed for react as it escapes by default
  resources: {
    en: { translation: en },
  },
});

export default i18n;
