import colors from 'Styles/colors';
import fonts from 'Styles/fonts';

const classes = {
  linkContainer: {
    display: 'flex',
    alignItems: 'center',

    '&:hover': {
      cursor: 'pointer',
    },
  },

  desktopMenuText: {
    color: colors.PRIMARY,
    fontFamily: fonts.fontFamily.Bold,
  },

  icon: {
    color: colors.PRIMARY,
    marginLeft: '-4px',
  },

  mobileIcon: {
    color: colors.WHITE,
    fontSize: 32,
    marginLeft: '-4px',
  },

  mobileContainer: {
    display: 'flex',
    alignItems: 'center',
    padding: '8px 16px',
  },

  item: {
    fontFamily: fonts.fontFamily.SemiBold,
    padding: '12px 16px',
  },

  mobileItem: {
    fontFamily: fonts.fontFamily.SemiBold,
    fontSize: fonts.fontSize.m,
    padding: '8px 48px',
    color: colors.WHITE,
  },
};

export default classes;
