import { useEffect, useRef, useState } from 'react';

import { Link, useNavigate } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import { useTranslation } from 'react-i18next';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';

import MyAccountNavigationItem from './MyAccountNavigationItem/MyAccountNavigationItem';
import Logo from 'Assets/Images/logo.png';
import { useAppSelector } from 'Store/store';
import useWindowSize from 'Hooks/useWindowSize';
import { FirstLevelRoutes } from 'Routing/route-paths';
import colors from 'Styles/colors';
import classes from './styles';

const NavigationBar = () => {
  const { t } = useTranslation();
  const [isMobileDrawerOpen, setIsMobileDrawerOpen] = useState(false);
  const navigationItems = [
    { title: t('navbar.home'), route: FirstLevelRoutes.LANDING_PAGE },
    { title: t('navbar.pricing'), route: FirstLevelRoutes.PRICING },
    { title: t('navbar.contact'), route: FirstLevelRoutes.CONTACT },
  ];
  const appBarRef = useRef<HTMLDivElement | null>(null);
  const userId = useAppSelector(state => state.user.user.id);
  const [innerWidth] = useWindowSize();
  const navigate = useNavigate();

  useEffect(() => {
    const onScroll = () => {
      if (appBarRef.current && window.pageYOffset < 140) {
        appBarRef.current.style.backgroundColor = `rgba(255, 255, 255, ${window.pageYOffset / 141})`;
        appBarRef.current.style.borderBottom = `1px solid rgba(246, 246, 246, ${window.pageYOffset * 0.005})`;
      }
    };

    window.removeEventListener('scroll', onScroll);
    window.addEventListener('scroll', onScroll, { passive: true });
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  const conditionallyRenderNavigationItem = (isMobile?: boolean) => {
    const style = isMobile ? { ...classes.manageTournamentButtonText, ...classes.mobileButtonText } : classes.manageTournamentButtonText;
    const buttonStyle = isMobile ? { ...classes.manageTournamentButton, ...classes.mobileButton } : classes.manageTournamentButton;

    if (userId) {
      return <MyAccountNavigationItem isMobile={isMobile ?? false} />;
    } else {
      return (
        <Button variant="outlined" sx={buttonStyle} onClick={() => navigate(`../${FirstLevelRoutes.SIGN_IN}`)}>
          <span style={style}>{t('navbar.sign_in')}</span>
        </Button>
      );
    }
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar ref={appBarRef} component="nav" sx={classes.appBar}>
        <Container>
          <Toolbar disableGutters>
            <Box sx={classes.logoContainer} onClick={() => navigate('../')}>
              <img style={classes.logo} src={Logo} alt="Logo" />
            </Box>

            <Box flexGrow={1} />

            <Box sx={classes.desktopMenu}>
              {navigationItems.map(item => (
                <Link key={item.title} style={classes.desktopMenuText} to={`../${item.route}`} replace>
                  {item.title}
                </Link>
              ))}
              {conditionallyRenderNavigationItem()}
            </Box>

            <IconButton
              style={classes.drawerTriggerButton}
              edge="end"
              onClick={() => setIsMobileDrawerOpen(prev => !prev)}
              sx={{ display: { sm: 'none' } }}>
              <MenuIcon />
            </IconButton>
          </Toolbar>
        </Container>
      </AppBar>

      <Box component="nav">
        <Drawer
          container={window !== undefined ? () => window.document.body : undefined}
          variant="temporary"
          open={isMobileDrawerOpen}
          anchor="right"
          onClose={() => setIsMobileDrawerOpen(prev => !prev)}
          ModalProps={{ keepMounted: true }} // Better open performance on mobile.
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: innerWidth, backgroundColor: colors.PRIMARY },
          }}>
          <Box onClick={() => setIsMobileDrawerOpen(prev => !prev)}>
            <List sx={classes.drawerList}>
              <IconButton
                style={{ ...classes.drawerTriggerButton, ...classes.mobileDrawerTriggerButton } as any}
                edge="end"
                onClick={() => setIsMobileDrawerOpen(prev => !prev)}
                sx={{ display: { sm: 'none' } }}>
                <MenuIcon />
              </IconButton>

              {navigationItems.map(item => (
                <ListItem key={item.title}>
                  <Link key={item.title} style={classes.mobileMenuText} to={`../${item.route}`}>
                    {item.title}
                  </Link>
                </ListItem>
              ))}

              {conditionallyRenderNavigationItem(true)}
            </List>
          </Box>
        </Drawer>
      </Box>
    </Box>
  );
};

export default NavigationBar;
