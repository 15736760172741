const colors = {
  PRIMARY: '#19CFD6',
  PRIMARY_OPACITY: '#19CFD61A',
  WHITE: '#ffffff',
  LIGHT_GREY: '#F6F6F6',
  MEDIUM_GREY: '#7A7A83',
  BORDER: '#eeeeee',
  ERROR: 'red',
  RED: '#dc2f02',
  DARK: '#111111',
  WARNING: '#FF851B',
};

export default colors;
