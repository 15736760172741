import { useState } from 'react';

import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import IconButton from '@mui/material/IconButton';
import { useNavigate } from 'react-router-dom';

import CustomTextField from 'Components/Atomic/CustomTextField/CustomTextField';
import ButtonWrapper from 'Components/Atomic/ButtonWrapper/ButtonWrapper';
import { saveUserCall } from 'API/Firestore/UsersCollection';
import { auth } from 'Services/firebase';
import useFormReducer from 'Hooks/useFormReducer';
import { validationDictionary } from 'Utils/validationUtils';
import { logError } from 'Utils/loggingUtils';
import { FirstLevelRoutes } from 'Routing/route-paths';
import { SignUpFields, signUpFieldsInitialState } from './initialState';
import classes from './styles';

const SignUpPage = () => {
  const [state, handleValueChange, getAreAllFieldsValid, validateInputs] = useFormReducer(signUpFieldsInitialState);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const createAccount = async () => {
    setErrorMessage('');
    setIsLoading(true);

    try {
      const response = await createUserWithEmailAndPassword(
        auth,
        state[SignUpFields.EMAIL].value.trimEnd(),
        state[SignUpFields.PASSWORD].value,
      );

      await saveUserCall(response.user.uid, state[SignUpFields.EMAIL].value.trimEnd(), state[SignUpFields.NAME].value.trimEnd());
      navigate(`../${FirstLevelRoutes.USER_DASHBOARD}`);
    } catch (error) {
      logError('Create account', error);
      handleError(error);
      setIsLoading(false);
    }
  };

  const handleError = (error: any) => {
    if (error.code === 'auth/email-already-in-use') {
      setErrorMessage(t('sign_up.email_already_in_use'));
    } else if (error.code === 'auth/weak-password') {
      setErrorMessage(t('sign_up.weak_password'));
    } else if (error.code === 'auth/invalid-email') {
      setErrorMessage(t('sign_up.invalid_email'));
    } else {
      setErrorMessage(t('common_error_messages.generic'));
    }
  };

  const handleInvalidFields = () => {
    validateInputs([
      { name: SignUpFields.NAME, constraints: validationDictionary.name },
      { name: SignUpFields.EMAIL, constraints: validationDictionary.email },
      { name: SignUpFields.PASSWORD, constraints: validationDictionary.password },
    ]);
  };

  return (
    <>
      <Container sx={classes.mainContainer}>
        <Typography variant="h1">{t('sign_up.title')}</Typography>

        <Typography variant="h3" sx={classes.subtitle}>
          {t('sign_up.subtitle')}
        </Typography>

        <Box sx={classes.rowContainer}>
          <CustomTextField
            label={t('sign_up.name_label')}
            placeholder={t('sign_up.name_placeholder')}
            type="text"
            required
            value={state[SignUpFields.NAME].value}
            error={!!state[SignUpFields.NAME].errorMessage}
            helperText={state[SignUpFields.NAME].isValid ? undefined : state[SignUpFields.NAME].errorMessage}
            onChange={e => handleValueChange(SignUpFields.NAME, e.target.value.trimStart(), validationDictionary.name)}
          />
        </Box>

        <Box sx={classes.rowContainer}>
          <CustomTextField
            label={t('sign_up.email_label')}
            placeholder={t('sign_up.email_placeholder')}
            type="email"
            required
            value={state[SignUpFields.EMAIL].value}
            error={!!state[SignUpFields.EMAIL].errorMessage}
            helperText={state[SignUpFields.EMAIL].isValid ? undefined : state[SignUpFields.EMAIL].errorMessage}
            onChange={e => handleValueChange(SignUpFields.EMAIL, e.target.value.trimStart(), validationDictionary.email)}
          />
        </Box>

        <Box sx={classes.rowContainer}>
          <CustomTextField
            label={t('sign_up.password_label')}
            placeholder={t('sign_up.password_placeholder')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" sx={classes.passwordIcon}>
                  <IconButton onClick={() => setIsPasswordVisible(prev => !prev)} edge="end">
                    {isPasswordVisible ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            type={isPasswordVisible ? 'text' : 'password'}
            required
            value={state[SignUpFields.PASSWORD].value}
            error={!!state[SignUpFields.PASSWORD].errorMessage}
            helperText={state[SignUpFields.PASSWORD].isValid ? undefined : state[SignUpFields.PASSWORD].errorMessage}
            onChange={e => handleValueChange(SignUpFields.PASSWORD, e.target.value, validationDictionary.name)}
          />
        </Box>

        <ButtonWrapper
          sx={classes.button}
          disabled={isLoading}
          isLoading={isLoading}
          onClick={() => (getAreAllFieldsValid() ? createAccount() : handleInvalidFields())}>
          {t('sign_up.create_button')}
        </ButtonWrapper>

        <Typography variant="h4" sx={classes.signInLink} onClick={() => navigate(`../${FirstLevelRoutes.SIGN_IN}`)}>
          {t('sign_up.already_have_account')}
        </Typography>

        {!!errorMessage && (
          <Typography variant="h4" marginTop="16px" color="error">
            {errorMessage}
          </Typography>
        )}
      </Container>
    </>
  );
};

export default SignUpPage;
