import { Suspense } from 'react';

import { Routes as Switch, Route, Navigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import * as Sentry from '@sentry/react';

import * as Pages from './pages';
import Dashboard from 'Pages/OrganizeTournament/Dashboard/Dashboard';
import Layout from 'Components/Molecules/Layout/Layout';
import ProtectedRoutes from './ProtectedRoute';
import AlreadyLoggedRoutes from './AlreadyLoggedRoutes';
import { FirstLevelRoutes, SecondLevelRoutes } from './route-paths';

const SentrySwitch = Sentry.withSentryReactRouterV6Routing(Switch);
const Routes = () => {
  return (
    <Suspense
      fallback={
        <Box display="flex" flex={1} alignItems="center" justifyContent="center">
          <CircularProgress size={56} thickness={4} />
        </Box>
      }>
      <SentrySwitch>
        <Route path={FirstLevelRoutes.MANAGE_TOURNAMENT} element={<Pages.ManageTournamentPage />}>
          <Route path={SecondLevelRoutes.DASHBOARD} element={<Dashboard />} />
          <Route path={SecondLevelRoutes.REGISTERED_TEAMS} element={<Pages.RegisteredTeamsContainer />} />
          {/* <Route path={SecondLevelRoutes.ADD_TEAMS} element={<AddTeamsContainer />} /> */}
          <Route path={SecondLevelRoutes.REGISTRATION} element={<Pages.Registration />} />
          <Route path={SecondLevelRoutes.GAMES_LIST} element={<Pages.RegisteredGamesContainer />} />
          <Route path={SecondLevelRoutes.BRACKET} element={<Pages.BracketContainer />} />
          {/* <Route path={SecondLevelRoutes.ADD_DELAY} element={<Pages.AddDelayContainer />} /> */}
          <Route path={SecondLevelRoutes.SEND_NOTIFICATION} element={<Pages.SendTournamentNotification />} />
          <Route path={SecondLevelRoutes.RECEIVED_MESSAGES} element={<Pages.ReceivedMessages />} />
        </Route>

        <Route element={<Layout />}>
          <Route path={FirstLevelRoutes.LANDING_PAGE} element={<Pages.LandingPage />} />
          <Route path={FirstLevelRoutes.CONTACT} element={<Pages.ContactPage />} />
          <Route path={FirstLevelRoutes.PRICING} element={<Pages.PricingPage />} />
          <Route path={`${FirstLevelRoutes.TOURNAMENT}/:tournamentId`} element={<Pages.TournamentPage />} />
          <Route path={FirstLevelRoutes.TERMS_AND_CONDITIONS} element={<Pages.TermsAndConditionsPage />} />
          <Route path={FirstLevelRoutes.PRIVACY_POLICY} element={<Pages.PrivacyPolicy />} />
          <Route path={FirstLevelRoutes.COOKIE_POLICY} element={<Pages.CookiePolicy />} />

          <Route element={<ProtectedRoutes />}>
            <Route path={FirstLevelRoutes.USER_DASHBOARD} element={<Pages.UserDashboardPage />} />
          </Route>

          <Route element={<AlreadyLoggedRoutes />}>
            <Route path={FirstLevelRoutes.CREATE_ACCOUNT} element={<Pages.SignUpPage />} />
            <Route path={FirstLevelRoutes.SIGN_IN} element={<Pages.SignInPage />} />
          </Route>
        </Route>

        <Route path="*" element={<Navigate to={FirstLevelRoutes.LANDING_PAGE} />} />
      </SentrySwitch>
    </Suspense>
  );
};

export default Routes;
