import fonts from 'Styles/fonts';

const classes = {
  container: {
    padding: { xs: '16px 24px', md: '24px 32px' },
    boxShadow: '0 5px 25px -3px rgb(17 18 19 / 4%)',
    borderRadius: '24px',

    '&:hover': {
      cursor: 'pointer',
    },
  },

  questionRow: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
  },

  icon: {
    transition: '200ms',
    transform: 'rotate(360deg)',
  },

  iconActive: {
    transform: 'rotate(450deg)',
  },

  answer: {
    textAlign: 'left',
    marginTop: { xs: '16px', sm: '24px' },
    fontSize: { xs: fonts.fontSize.s, md: fonts.fontSize.m },
  },
};

export default classes;
