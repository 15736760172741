import { Dayjs } from 'dayjs';

import { PricingPlansIds } from 'Models/Pricing';
import { Tournament, TournamentDetails } from 'Models/Tournament';
import { TournamentPrivateSettings } from 'Models/TournamentPrivate';

export interface TournamentState {
  tournament: Tournament & TournamentDetails;
  tournamentDays: Dayjs[];
  privateSettings: TournamentPrivateSettings;
  isLoading: boolean;
  isSaving: boolean;
  isSavingTournament: boolean;
}

export const tournamentInitialState: TournamentState = {
  tournament: {
    description: '',
    id: '',
    organizer: '',
    registrationStartDateUtc: '',
    registrationEndDateUtc: '',
    registrationTax: '',
    textRegistrationFields: [],
    title: '',
    creatorId: '',
    categories: [],
    endDateUtc: '',
    createdDateUtc: '',
    isPrivate: false,
    location: {
      description: '',
      matched_substrings: [],
      place_id: '',
      structured_formatting: { main_text: '', main_text_matched_substrings: [], secondary_text: '' },
      terms: [],
      types: [],
      distance_meters: 0,
    },
    startDateUtc: '',
    country: '',
    timezone: '',
  },
  tournamentDays: [],
  privateSettings: { planId: PricingPlansIds.FREE },
  isLoading: true,
  isSaving: false,
  isSavingTournament: false,
};
