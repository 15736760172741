import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import CopyrightIcon from '@mui/icons-material/Copyright';
import CircleIcon from '@mui/icons-material/Circle';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

import SvgSizer from '../SvgSizer/SvgSizer';
import AppStoreBadge from 'Assets/Illustrations/app-store.svg';
import GooglePlayBadge from 'Assets/Illustrations/google-play.svg';
import Logo from 'Assets/Images/logo.png';
import { FirstLevelRoutes } from 'Routing/route-paths';
import classes from './styles';

const Footer = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const shouldShowMobileApp =
    location.pathname === FirstLevelRoutes.LANDING_PAGE ||
    location.pathname.includes(FirstLevelRoutes.CONTACT) ||
    location.pathname.includes(FirstLevelRoutes.PRICING);

  return (
    <Container sx={classes.container}>
      {shouldShowMobileApp && (
        <Box sx={classes.mobileAppContainer}>
          <Box sx={classes.logoContainer}>
            <img style={classes.logo} src={Logo} alt="Logo" />
          </Box>

          <Typography variant="h1" sx={classes.title}>
            {t('footer.title')}
          </Typography>

          <Typography variant="h3" sx={classes.subtitle}>
            {t('footer.subtitle')}
          </Typography>

          <Box sx={classes.badgesContainer}>
            <a target="_blank" href="https://apps.apple.com/us/app/block-out/id1585345867?itsct=apps_box_badge&amp;itscg=30200">
              <SvgSizer component={AppStoreBadge} xs="122px" sm="150px" md="182px" />
            </a>

            <a
              target="_blank"
              href="https://play.google.com/store/apps/details?id=com.volleyballleague&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
              <SvgSizer component={GooglePlayBadge} xs="138px" sm="169px" md="205px" />
            </a>
          </Box>
        </Box>
      )}

      <Box sx={classes.footerLinks}>
        <Box sx={classes.copyrightContainer}>
          <CopyrightIcon fontSize="small" />

          <Typography sx={classes.copyrightText}>{new Date().getFullYear()} Blockout</Typography>
        </Box>

        <CircleIcon sx={classes.circleIcon} />

        <Link style={classes.link} to={`../${FirstLevelRoutes.TERMS_AND_CONDITIONS}`}>
          {t('navbar.terms_and_conditions')}
        </Link>

        <Link style={classes.link} to={`../${FirstLevelRoutes.PRIVACY_POLICY}`}>
          {t('navbar.privacy_policy')}
        </Link>

        <Link style={classes.link} to={`../${FirstLevelRoutes.COOKIE_POLICY}`}>
          {t('navbar.cookie_policy')}
        </Link>
      </Box>
    </Container>
  );
};

export default Footer;
