import colors from 'Styles/colors';
import fonts from 'Styles/fonts';

const classes = {
  mainContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    textAlign: 'center',
    paddingTop: { xs: '64px', sm: '104px', md: '140px' },
    paddingLeft: { xs: '24px!important', sm: '48px!important', md: '104px!important' },
    paddingRight: { xs: '24px!important', sm: '48px!important', md: '104px!important' },
  },

  title: {
    fontSize: { xs: fonts.fontSize.xl, sm: '48px!important', md: '66px!important' },
  },

  titleSecondPart: {
    color: colors.PRIMARY,
  },

  subtitle: {
    marginTop: { xs: '24px', sm: '32px' },
    paddingLeft: { xs: '24px!important', sm: '88px!important', md: '164px!important' },
    paddingRight: { xs: '24px!important', sm: '88px!important', md: '164px!important' },
    marginBottom: '40px',
    color: colors.MEDIUM_GREY,
    fontFamily: fonts.fontFamily.Regular,
    fontSize: { xs: fonts.fontSize.m, sm: fonts.fontSize.lg },
  },

  mainContainerImages: {
    display: 'flex',

    '& > img': {
      height: { xs: '280px', sm: '500px', md: '600px', lg: '750px' },
    },

    '& :first-child': {
      marginTop: { xs: '32px', sm: '60px' },
      transform: 'rotate(-14deg)',
    },

    '& :last-child': {
      transform: 'rotate(14deg)',
    },
  },

  getStartedButton: {
    padding: { xs: '22px 28px', sm: '28px 36px' },
    borderRadius: '32px',
    marginBottom: { xs: '24px', sm: '48px', md: '60px' },
    boxShadow: 'rgb(8 187 193 / 10%) 0px 3px 13px 4px',
  },

  getStartedButtonText: {
    fontFamily: fonts.fontFamily.Bold,
    fontSize: { xs: fonts.fontSize.m, md: fonts.fontSize.lg },
  },

  chip: {
    padding: '6px 12px',
    borderRadius: '20px',
    marginBottom: { xs: '4px', sm: '8px' },
    display: 'inline-block',
  },

  primaryChip: {
    backgroundColor: colors.PRIMARY_OPACITY,

    '& *': {
      color: colors.PRIMARY,
    },
  },

  secondaryChip: {
    backgroundColor: '#0074D91A',
    alignSelf: 'flex-start',

    '& *': {
      color: '#0074D9',
    },
  },

  thirdChip: {
    backgroundColor: '#FF851B1A',
    alignSelf: 'flex-start',

    '& *': {
      color: '#FF851B',
    },
  },

  fourthChip: {
    backgroundColor: '#B10DC91A',

    '& *': {
      color: '#B10DC9',
    },
  },

  fifthhChip: {
    backgroundColor: '#00AB661A',

    '& *': {
      color: '#00AB66',
    },
  },

  section: {
    marginTop: { xs: '82px', sm: '122px', md: '154px' },
    paddingLeft: { sm: '40px' },
    paddingRight: { sm: '40px' },

    '&:first-of-type': {
      marginTop: { xs: '32px', sm: '72px', md: '96px' },
    },
  },

  webAppInfoSectionImageContainer: {
    overflow: 'hidden',
    position: { xs: 'relative', md: 'absolute' },
    right: 0,
    marginBottom: { xs: '40px', md: '0px' },
    borderRadius: { xs: '16px', md: '24px' },
    // Hack to nicely render shadow
    marginTop: { xs: '0px', md: '40px' },
    paddingBottom: { xs: '0px', md: '40px' },
    boxShadow: { xs: 'rgba(149, 157, 165, 0.2) 0px 8px 24px', md: 'unset' },

    '& div': {
      display: 'flex',
      justifyContent: 'flex-end',
      marginLeft: { xs: '0px', md: '140px' },
      width: { md: '120%' },
    },

    '& img': {
      width: '100%',
      height: 'auto',
      boxShadow: { xs: 'unset', md: 'rgba(149, 157, 165, 0.2) 0px 8px 24px' },
      borderRadius: { xs: '16px', md: '24px' },
    },
  },

  mobileAppInfoSectionImageContainer: {
    display: 'flex',
    justifyContent: 'center',

    '& img': {
      height: { xs: '500px', md: '800px' },
    },
  },

  centeredSection: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    textAlign: 'center',
  },

  sectionTitle: {
    fontSize: { xs: fonts.fontSize.lg, sm: fonts.fontSize.xl, md: '48px!important' },
    marginBottom: { xs: '16px', sm: '24px' },
  },

  sectionTitlePadding: {
    maxWidth: { xs: '100%', sm: '50%', md: '47.5%' },
  },

  sectionSubtitle: {
    fontSize: { xs: fonts.fontSize.m - 2, sm: fonts.fontSize.lg - 2 },
    color: colors.MEDIUM_GREY,
    maxWidth: { xs: '100%', sm: '65%', md: '75%' },
  },

  sectionSubtitlePadding: {
    paddingLeft: { xs: '24px!important', sm: '48px!important', md: '104px!important' },
    paddingRight: { xs: '24px!important', sm: '48px!important', md: '104px!important' },
    paddingBottom: '48px',
  },

  sectionCard: {
    padding: '40px 56px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: '20px',
    boxShadow: '0 18px 35px -5px rgb(17 18 19 / 4%)',
  },

  howItWorksSectionCard: {
    width: '100%',
    padding: 0,
    height: '240px',
    justifyContent: 'center',
  },

  sectionCardTitle: {
    marginTop: '40px',
    marginBottom: '24px',
  },

  sectionStepTitle: {
    fontSize: { xs: fonts.fontSize.s, sm: fonts.fontSize.m, md: fonts.fontSize.lg },
    fontFamily: fonts.fontFamily.Bold,
    marginTop: '24px',
    marginBottom: { xs: '16px', sm: '24px' },
  },

  checkRow: {
    display: 'flex',
    gap: '12px',
    marginTop: '40px',
  },

  checkrowSubtitle: {
    marginTop: '8px',
    fontSize: { xs: fonts.fontSize.s, sm: fonts.fontSize.m },
  },

  stepCount: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.WHITE,
    marginTop: '-25px',
    width: '50px',
    height: '50px',
    borderRadius: '25px',
    boxShadow: '0 18px 35px -5px rgb(17 18 19 / 4%)',

    '& *': {
      fontFamily: fonts.fontFamily.Bold,
      fontSize: { xs: fonts.fontSize.s, sm: fonts.fontSize.m, md: fonts.fontSize.lg },
    },
  },

  sectionTextContainer: {
    textAlign: 'center',
    paddingLeft: { xs: '8px', sm: '16px', md: '24px', lg: '32px' },
    paddingRight: { xs: '8px', sm: '16px', md: '24px', lg: '32px' },
  },
};

export default classes;
