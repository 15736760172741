import { createSlice } from '@reduxjs/toolkit';

import { tournamentInitialState } from './state';
import extraReducers from './extraReducers';
import { setTournamentReducer } from './reducers';

const tournamentSlice = createSlice({
  name: 'tournament',
  initialState: tournamentInitialState,
  reducers: { setTournament: setTournamentReducer },
  extraReducers,
});

export default tournamentSlice.reducer;

export const { setTournament } = tournamentSlice.actions;
