import { collection, doc, getDoc, getDocs, orderBy, query, Timestamp, updateDoc, where } from 'firebase/firestore';

import { GetTournamentData, Tournament, UpdateTournamentParams } from 'Models/Tournament';
import { firestore } from 'Services/firebase';
import { convertDateToUtc } from 'Utils/dateUtils';

export const getTournamentCall = async (tournamentId: string): Promise<Tournament | undefined> => {
  const docRef = doc(firestore, 'Tournaments', tournamentId);
  const docSnap = await getDoc(docRef);
  const data = docSnap.data();

  if (!data) {
    return undefined;
  }

  const startDateUtc = convertDateToUtc(new Timestamp(data.startDate.seconds, data.startDate.nanoseconds).toDate());
  const endDateUtc = convertDateToUtc(new Timestamp(data.endDate.seconds, data.endDate.nanoseconds).toDate());

  delete data.startDate;
  delete data.endDate;

  return { ...data, id: docSnap.id, startDateUtc, endDateUtc } as Tournament;
};

export const getUserTournamentsCall = async (userId: string) => {
  const q = query(collection(firestore, 'Tournaments'), where('creatorId', '==', userId), orderBy('startDate'));

  const response = await getDocs(q);

  return response.docs.map(doc => {
    const data = doc.data() as GetTournamentData;

    return {
      ...data,
      id: doc.id,
      startDateUtc: convertDateToUtc(new Timestamp(data.startDate.seconds, data.startDate.nanoseconds).toDate()),
      endDateUtc: convertDateToUtc(new Timestamp(data.endDate.seconds, data.endDate.nanoseconds).toDate()),
      createdDateUtc: convertDateToUtc(new Timestamp(data.createdDate.seconds, data.createdDate.nanoseconds).toDate()),
    };
  });
};

export const updateTournamentCall = async (tournamentId: string, tournament: UpdateTournamentParams) => {
  const docRef = doc(firestore, 'Tournaments', tournamentId);

  await updateDoc(docRef, tournament);
};
