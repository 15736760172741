import colors from 'Styles/colors';
import fonts from 'Styles/fonts';

const classes = {
  appBar: {
    backgroundColor: colors.WHITE,
    padding: '12px 0',
    boxShadow: 'none',
    backdropFilter: 'blur(6px)',
  },

  logoContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '150px',

    '&:hover': {
      cursor: 'pointer',
    },
  },

  logo: {
    width: '100%',
    color: colors.PRIMARY,
  },

  drawerTriggerButton: {
    backgroundColor: colors.WHITE,
    color: colors.DARK,
    marginRight: 0,
    padding: '12px',
    borderRadius: '40px',
    boxShadow: '0 22px 40px -5px rgb(17 18 19 / 12%)',
  },

  mobileDrawerTriggerButton: {
    position: 'absolute',
    right: '24px',
    top: 0,
  },

  drawerList: {
    marginTop: '16px',

    '> li:nth-of-type(1)': {
      marginTop: '-8px',
    },
  },

  desktopMenu: {
    display: { xs: 'none', sm: 'flex' },
    alignItems: 'center',
    gap: '24px',
  },

  manageTournamentButton: {
    borderRadius: '26px',
  },

  manageTournamentButtonText: {
    fontFamily: fonts.fontFamily.Bold,
    color: colors.PRIMARY,
    textDecoration: 'none',
  },

  desktopMenuText: {
    padding: '8px',
    fontFamily: fonts.fontFamily.SemiBold,
    color: colors.MEDIUM_GREY,
    textDecoration: 'none',
  },

  mobileMenuText: {
    padding: '0px 16px',
    fontFamily: fonts.fontFamily.Bold,
    fontSize: fonts.fontSize.lg,
    color: colors.WHITE,
    textDecoration: 'none',
  },

  mobileButton: {
    marginTop: '16px',
    marginLeft: '32px',
    backgroundColor: colors.WHITE,
  },

  mobileButtonText: {
    color: colors.PRIMARY,
  },
};

export default classes;
