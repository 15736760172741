import { useEffect } from 'react';

import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';
import { useLocation, useNavigationType, createRoutesFromChildren, matchRoutes } from 'react-router-dom';

import App from './App';
import reportWebVitals from './reportWebVitals';
import 'Translation/i18n';
import './index.css';

Sentry.init({
  dsn: 'https://a3f7dd21f2bfe0b3219b6fbe05700de3@o4506253364297728.ingest.sentry.io/4506253366132736',
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      ),
    }),
    new Sentry.Replay(),
  ],

  environment: process.env.REACT_APP_SENTRY_ENV,

  // // Set tracesSampleRate to 1.0 to capture 100%
  // // of transactions for performance monitoring.
  tracesSampleRate: process.env.REACT_APP_SENTRY_ENV === 'development' ? 0.1 : 1.0,

  // // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  // tracePropagationTargets: ['http://localhost:3001', /^https:\/\/blcokoutapp\.com/],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: process.env.REACT_APP_SENTRY_ENV === 'development' ? 0 : 0.05,
  replaysOnErrorSampleRate: process.env.REACT_APP_SENTRY_ENV === 'development' ? 0 : 1.0,
});

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
