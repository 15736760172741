import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  addRegisteredTeamCall,
  bulkEditTeamPointsCall,
  deleteTeamCall,
  getAllTeamsCall,
  updateTeamCall,
} from 'API/Firestore/TournamentTeamsCollection';
import { updateTeamsLastUpdatedCall } from 'API/Firestore/TournamentGeneralCollection';
import { updateGamesByTeamNameCall } from 'API/Firestore/TournamentGamesCollection';
import i18n from 'Translation/i18n';
import { RootState } from 'Store/store';
import { openSnackbar } from 'Store/UI/slice';
import { AddRegisteredTeamParams, RegisteredTeam } from 'Models/RegisteredTeam';
import {
  AddRegisteredTeamActionParams,
  BulkEditTeamPointsActionParams,
  DeleteRegisteredTeamActionParams,
  UpdateRegisteredTeamActionParams,
  UpdateRegisteredTeamTaxPaidActionParams,
} from './models';
import { addTeamIdDisabledCheckbox } from './slice';
import { deletePendingTeamCall } from 'API/Firestore/TournamentPendingTeamsCollection';
import { convertDateToUtc } from 'Utils/dateUtils';

export const getAllRegisteredTeams = createAsyncThunk('teams/getAllRegisteredTeams', async (tournamentId: string, { getState }) => {
  const teams = await getAllTeamsCall(tournamentId);

  return { teams, allCategories: (getState() as RootState).tournament.tournament.categories };
});

export const addRegisteredTeam = createAsyncThunk(
  'teams/addRegisteredTeam',
  async ({ tournamentId, pendingTeam, isTaxPaid, callback }: AddRegisteredTeamActionParams) => {
    const registeredTeam: AddRegisteredTeamParams = {
      name: pendingTeam.name,
      category: pendingTeam.category,
      playerOneName: pendingTeam.playerOne,
      playerTwoName: pendingTeam.playerTwo,
      registrationTaxPaid: isTaxPaid,
      registrationFields: pendingTeam.registrationFields,
      points: pendingTeam.points,
      addedDate: new Date(),
    };

    const [id] = await Promise.all([
      addRegisteredTeamCall(tournamentId, registeredTeam),
      pendingTeam.id ? deletePendingTeamCall(tournamentId, pendingTeam.id) : Promise.resolve(),
    ]);
    callback();
    updateTeamsLastUpdatedCall(tournamentId);

    return { team: { ...registeredTeam, id, addedDateUtc: convertDateToUtc(registeredTeam.addedDate) } };
  },
);

export const deleteRegisteredTeam = createAsyncThunk(
  'teams/deleteRegisteredTeam',
  async ({ teamId, category, callback }: DeleteRegisteredTeamActionParams, { getState, dispatch }) => {
    const tournamentId = (getState() as RootState).tournament.tournament.id;

    await deleteTeamCall(tournamentId, teamId);
    dispatch(openSnackbar(i18n.t('teams_list.team_deleted_successfully')));
    callback();
    updateTeamsLastUpdatedCall(tournamentId);

    return { teamId, category };
  },
);

export const updateRegisteredTeam = createAsyncThunk(
  'teams/updateRegisteredTeam',
  async ({ previousTeamName, updatedTeam, category, callback }: UpdateRegisteredTeamActionParams, { getState, dispatch }) => {
    const tournamentId = (getState() as RootState).tournament.tournament.id;

    await updateTeamCall(tournamentId, updatedTeam);
    if (previousTeamName !== updatedTeam.name) {
      updateGamesByTeamNameCall(tournamentId, updatedTeam.id, updatedTeam.name);
    }
    dispatch(openSnackbar(i18n.t('teams_list.team_updated_successfully')));
    callback();
    updateTeamsLastUpdatedCall(tournamentId);

    return { updatedTeam, category };
  },
);

export const updateRegisteredTeamTaxPaid = createAsyncThunk(
  'teams/updateRegisteredTeamTaxPaid',
  async ({ team, category, isChecked }: UpdateRegisteredTeamTaxPaidActionParams, { getState, dispatch }) => {
    dispatch(addTeamIdDisabledCheckbox(team.id));

    const tournamentId = (getState() as RootState).tournament.tournament.id;
    const updatedTeam: RegisteredTeam = { ...team, registrationTaxPaid: isChecked };

    await updateTeamCall(tournamentId, updatedTeam);
    dispatch(openSnackbar(i18n.t('common.successful_update')));

    return { updatedTeam, category };
  },
);

export const bulkEditTeamPoints = createAsyncThunk(
  'teams/bulkEditTeamPoints',
  async ({ teamPoints, category, callback }: BulkEditTeamPointsActionParams, { getState, dispatch }) => {
    const tournamentId = (getState() as RootState).tournament.tournament.id;

    await bulkEditTeamPointsCall(tournamentId, teamPoints);
    dispatch(openSnackbar(i18n.t('teams_list.update_points.successful_update')));
    callback();

    return { teamPoints, category };
  },
);
