import { useEffect } from 'react';

import { Dayjs } from 'dayjs';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from 'Store/store';
import { openErrorSnackbar } from 'Store/UI/slice';
import useFormReducer from 'Hooks/useFormReducer';
import useCustomReducer from 'Hooks/useCustomReducer';
import { dashboardInitialState, DashboardTextFields, dashboardTextFieldsInitialState } from './initialState';
import { getDayJsDateInTournamentTimezone } from 'Utils/dateUtils';
import { validationDictionary } from 'Utils/validationUtils';
import { UpdateTournamentDetailsParams, UpdateTournamentParams } from 'Models/Tournament';
import { updateTournament } from 'Store/Tournament/actions';
import { PricingPlansIds } from 'Models/Pricing';

const useDashboard = () => {
  const tournament = useAppSelector(state => state.tournament.tournament);
  const tournamentPrivateSettings = useAppSelector(state => state.tournament.privateSettings);
  const [textFieldsState, handleValueChange, getAreAllTextFieldsValid, validateInputs, updateFormState] =
    useFormReducer(dashboardTextFieldsInitialState);
  const [state, updateState] = useCustomReducer(dashboardInitialState);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const isOnFreePlan = tournamentPrivateSettings.planId === PricingPlansIds.FREE;

  useEffect(() => {
    if (tournament.id) {
      updateFormState({
        [DashboardTextFields.TOURNAMENT_NAME]: { value: tournament.title, isValid: true, errorMessage: '' },
        [DashboardTextFields.ORGANIZER]: { value: tournament.organizer, isValid: true, errorMessage: '' },
        [DashboardTextFields.DESCRIPTION]: { value: tournament.description, isValid: true, errorMessage: '' },
      });
      updateState({
        startDate: getDayJsDateInTournamentTimezone(tournament.startDateUtc),
        endDate: getDayJsDateInTournamentTimezone(tournament.endDateUtc),
        selectedCountry: tournament.country,
        selectedTimezone: tournament.timezone,
        categories: tournament.categories,
        location: tournament.location,
        isPrivate: tournament.isPrivate,
      });
    }
  }, [tournament]);

  const saveTournament = () => {
    const updatedTournament: UpdateTournamentParams = {
      title: textFieldsState[DashboardTextFields.TOURNAMENT_NAME].value,
      description: textFieldsState[DashboardTextFields.DESCRIPTION].value,
      country: state.selectedCountry,
      startDate: state.startDate.tz(state.selectedTimezone).startOf('day').toDate(),
      endDate: state.endDate.tz(state.selectedTimezone).endOf('day').toDate(),
      location: state.location!,
      timezone: state.selectedTimezone,
      isPrivate: state.isPrivate,
    };
    const updatedTournamentDetails: UpdateTournamentDetailsParams = {
      organizer: textFieldsState[DashboardTextFields.ORGANIZER].value,
      categories: state.categories,
    };

    dispatch(updateTournament({ tournament: updatedTournament, tournamentDetails: updatedTournamentDetails }));
  };

  const getAreAllFieldsValid = () => {
    const isStartDateHigher = state.startDate.diff(state.endDate, 'day', true) > 0;

    return (
      getAreAllTextFieldsValid() &&
      !!state.selectedCountry &&
      !!state.selectedTimezone &&
      !isStartDateHigher &&
      state.startDate.isValid() &&
      state.endDate.isValid()
    );
  };

  const handleInvalidFields = () => {
    const isStartDateHigher = state.startDate.diff(state.endDate, 'day', true) > 0;
    let updatedStateErrorMessages = { ...state };

    if (isStartDateHigher) {
      updatedStateErrorMessages = { ...updatedStateErrorMessages, dateErrorMessage: t('dashboard.form.start_date_too_high') };
    }

    if (!state.selectedCountry || !state.selectedTimezone) {
      updatedStateErrorMessages = {
        ...updatedStateErrorMessages,
        locationErrorMessage: state.location ? t('dashboard.form.location_invalid') : t('dashboard.form.location_required'),
      };
    }

    updateState(updatedStateErrorMessages);
    validateInputs([
      { name: DashboardTextFields.TOURNAMENT_NAME, constraints: validationDictionary.tournamentName },
      { name: DashboardTextFields.ORGANIZER, constraints: validationDictionary.organizer },
      { name: DashboardTextFields.DESCRIPTION, constraints: validationDictionary.tournamentDescription },
    ]);
  };

  const handleLocationChange = (location: google.maps.places.AutocompletePrediction | null) => {
    updateState({ location, locationErrorMessage: '' });
  };

  const handleTimezoneChange = (country: string, timezone: string) => {
    updateState({ selectedTimezone: timezone, selectedCountry: country });
  };

  const handleStartDateChange = (date: Dayjs) => {
    updateState({ startDate: date, dateErrorMessage: '' });
  };

  const handleEndDateChange = (date: Dayjs) => {
    updateState({ endDate: date, dateErrorMessage: '' });
  };

  const handlePrivacyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!isOnFreePlan) {
      updateState({ isPrivate: event.target.checked });
    }
  };

  const addCategory = () => {
    if (state.categories.find(el => el.toLowerCase() === state.categoryInput.toLowerCase())) {
      updateState({ categoryErrorMessage: t('dashboard.form.category_already_exists') });
    } else {
      updateState({ categories: [...state.categories, state.categoryInput], categoryInput: '', categoryErrorMessage: '' });
    }
  };

  const removeCategory = (category: string) => {
    if (state.categories.length === 1) {
      dispatch(openErrorSnackbar(t('dashboard.form.one_category_required')));
    } else {
      updateState({ categories: state.categories.filter(c => c !== category) });
    }
  };

  return {
    textFieldsState,
    state,
    isOnFreePlan,
    save: () => (getAreAllFieldsValid() ? saveTournament() : handleInvalidFields()),
    updateCategoryInput: (categoryInput: string) => updateState({ categoryInput, categoryErrorMessage: '' }),
    addCategory,
    removeCategory,
    handleValueChange,
    handleLocationChange,
    handleTimezoneChange,
    handleStartDateChange,
    handleEndDateChange,
    handlePrivacyChange,
  };
};

export default useDashboard;
