import { doc, getDoc, setDoc } from 'firebase/firestore';

import { firestore } from 'Services/firebase';
import { User } from 'Models/User';

export const saveUserCall = async (userId: string, email: string, name: string) => {
  await setDoc(doc(firestore, 'Users', userId), { name, email });
};

export const getUserCall = async (userId: string) => {
  const response = await getDoc(doc(firestore, 'Users', userId));

  return { id: response.id, ...response.data() } as User;
};
