import { createSlice } from '@reduxjs/toolkit';

import { teamsInitialState } from './state';
import * as teamsReducers from './reducers';
import extraReducers from './extraReducers';

const teamsSlice = createSlice({
  name: 'teams',
  initialState: teamsInitialState,
  reducers: {
    addTeamIdDisabledCheckbox: teamsReducers.addTeamIdDisabledCheckboxReducer,
  },
  extraReducers,
});

export default teamsSlice.reducer;

export const { addTeamIdDisabledCheckbox } = teamsSlice.actions;
