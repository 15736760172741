import { useState } from 'react';

import { sendContactMessageCall } from 'API/Firestore/ContactCollection';
import useFormReducer from 'Hooks/useFormReducer';
import { validationDictionary } from 'Utils/validationUtils';
import { ContactFields, contactInitialState } from './initialState';
import { logError } from 'Utils/loggingUtils';

const useContactPage = () => {
  const [state, handleValueChange, getAreAllFieldsValid, validateInputs, updateFormState] = useFormReducer(contactInitialState);
  const [isLoading, setIsLoading] = useState(false);
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);

  const handleInvalidFields = () => {
    validateInputs([
      { name: ContactFields.NAME, constraints: validationDictionary.contact.name },
      { name: ContactFields.EMAIL, constraints: validationDictionary.email },
      { name: ContactFields.MESSAGE, constraints: validationDictionary.contact.message },
    ]);
  };

  const sendMessage = async () => {
    setIsLoading(true);

    try {
      await sendContactMessageCall(state[ContactFields.NAME].value, state[ContactFields.EMAIL].value, state[ContactFields.MESSAGE].value);

      updateFormState(contactInitialState);
      setIsSnackbarOpen(true);
    } catch (error) {
      logError('Send contact form', error);
    }

    setIsLoading(false);
  };

  return {
    state,
    isLoading,
    isSnackbarOpen,
    handleSendClick: () => (getAreAllFieldsValid() ? sendMessage() : handleInvalidFields()),
    closeSnackbar: () => setIsSnackbarOpen(false),
    handleValueChange,
  };
};

export default useContactPage;
