import colors from 'Styles/colors';
import fonts from 'Styles/fonts';

const classes = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: { xs: '100%', sm: '600px', md: '700px', lg: '800px' },
  },

  mobileAppContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    marginTop: { xs: '106px', sm: '142px', md: '184px' },
    marginBottom: { xs: '112px', sm: '156px', md: '180px' },
  },

  logoContainer: {
    width: { xs: '240px', sm: '280px', md: '320px' },
  },

  logo: {
    width: '100%',
    marginBottom: '56px',
  },

  title: {
    fontSize: { xs: fonts.fontSize.xl, sm: '48px!important', md: '66px!important' },
  },

  subtitle: {
    marginTop: { xs: '24px', sm: '32px' },
    marginBottom: '40px',
    color: colors.MEDIUM_GREY,
    fontFamily: fonts.fontFamily.Regular,
    fontSize: { xs: fonts.fontSize.m, sm: fonts.fontSize.lg },
  },

  badgesContainer: {
    gap: '24px',
    display: 'flex',
    alignItems: 'center',
  },

  footerLinks: {
    display: 'flex',
    gap: { xs: '16px', sm: '24px' },
    flexDirection: { xs: 'column', sm: 'row' },
    alignItems: 'center',
    paddingBottom: '32px',
  },

  link: {
    fontFamily: fonts.fontFamily.SemiBold,
    color: colors.MEDIUM_GREY,
    textDecoration: 'none',
  },

  copyrightContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
    color: colors.MEDIUM_GREY,
  },

  copyrightText: {
    fontFamily: fonts.fontFamily.SemiBold,
  },

  circleIcon: {
    fontSize: 8,
    color: colors.MEDIUM_GREY,
  },
};

export default classes;
