import { deleteField, doc, getDoc, updateDoc } from 'firebase/firestore';

import { BracketSize, BracketType, BracketTypes, CategoryBracketType } from 'Models/Bracket';
import { firestore } from 'Services/firebase';

export const getBracketTypesCall = async (tournamentId: string) => {
  const docRef = doc(firestore, `Tournament/${tournamentId}/General/Bracket`);
  const response = (await getDoc(docRef)).data() as BracketTypes;

  return response;
};

export const saveBracketCall = async (tournamentId: string, category: string, type: BracketType, size: BracketSize) => {
  const payload: CategoryBracketType = { type, size };
  const docRef = doc(firestore, `Tournament/${tournamentId}/General/Bracket`);
  await updateDoc(docRef, { [category]: payload });
};

export const removeBracketCall = async (tournamentId: string, category: string) => {
  const docRef = doc(firestore, `Tournament/${tournamentId}/General/Bracket`);

  await updateDoc(docRef, { [category]: deleteField() });
};

export const updateTeamsLastUpdatedCall = async (tournamentId: string) => {
  const docRef = doc(firestore, `Tournament/${tournamentId}/General/LastUpdated`);

  await updateDoc(docRef, { teamsLastUpdatedDate: new Date() });
};

export const updateScheduleLastUpdatedCall = async (tournamentId: string) => {
  const docRef = doc(firestore, `Tournament/${tournamentId}/General/LastUpdated`);

  await updateDoc(docRef, { scheduleLastUpdatedDate: new Date() });
};
