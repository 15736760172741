import colors from 'Styles/colors';
import fonts from 'Styles/fonts';

const classes = {
  mainContainer: {
    paddingTop: { xs: '32px', sm: '64px', md: '86px' },
  },

  subtitle: {
    fontFamily: fonts.fontFamily.Regular,
    color: colors.MEDIUM_GREY,
    marginTop: '4px',
    marginBottom: { xs: '24px', sm: '40px', md: '48px' },
  },

  rowContainer: {
    marginTop: '24px',
  },

  button: {
    marginTop: '40px',
  },

  passwordIcon: {
    marginRight: '8px',
  },

  signInLink: {
    paddingBlock: '8px',
    marginTop: '4px',
    color: colors.PRIMARY,

    '&:hover': {
      cursor: 'pointer',
    },
  },
};

export default classes;
