import colors from 'Styles/colors';

const classes = {
  optionsDropdownContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginRight: '16px',
    paddingBottom: '4px',

    '& p': {
      color: colors.MEDIUM_GREY,
    },
  },
};

export default classes;
