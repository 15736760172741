import useMediaQuery from '@mui/material/useMediaQuery';

import { theme } from 'Styles/muiTheme';

type Props = {
  component: string;
  xs?: number | string;
  sm?: number | string;
  md?: number | string;
  lg?: number | string;
};

const SvgSizer = (props: Props) => {
  const isXSSize = useMediaQuery(theme.breakpoints.only('xs'));
  const isSMSize = useMediaQuery(theme.breakpoints.only('sm'));
  const isMDSize = useMediaQuery(theme.breakpoints.only('md'));

  const getSize = () => {
    if (isXSSize) {
      return props.xs;
    } else if (isSMSize) {
      return props.sm;
    } else if (isMDSize) {
      return props.md;
    } else {
      return props.lg || props.md;
    }
  };

  return <img src={props.component} width={getSize()} />;
};

export default SvgSizer;
