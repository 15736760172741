import { ActionReducerMapBuilder } from '@reduxjs/toolkit';

import * as UserActions from './actions';
import { UserState } from './state';

const extraReducers = (builder: ActionReducerMapBuilder<UserState>) => {
  getUser(builder);
};

const getUser = (builder: ActionReducerMapBuilder<UserState>) => {
  builder
    .addCase(UserActions.getUser.pending, state => {
      state.isLoading = true;
    })
    .addCase(UserActions.getUser.fulfilled, (state, action) => {
      state.user = action.payload;
      state.isLoading = false;
    })
    .addCase(UserActions.getUser.rejected, state => {
      state.isLoading = false;
    });
};

export default extraReducers;
