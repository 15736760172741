import i18n from 'Translation/i18n';

export const validationDictionary = {
  tournamentName: {
    presence: { message: i18n.t('dashboard.form.tournament_name_required') },
    length: { maximum: 60, tooLong: i18n.t('dashboard.form.tournament_name_too_long', { count: 60 }) },
  },

  organizer: {
    presence: { message: i18n.t('dashboard.form.organizer_required') },
    length: { maximum: 80, tooLong: i18n.t('dashboard.form.organizer_too_long', { count: 80 }) },
  },

  tournamentDescription: {
    // presence: { message: i18n.t('dashboard.form.description_required') },
    length: { maximum: 250, tooLong: i18n.t('dashboard.form.description_too_long', { count: 250 }) },
  },

  teamName: {
    presence: { message: i18n.t('teams_list.add_team.team_name_required') },
    length: { maximum: 80, tooLong: i18n.t('common_error_messages.team_name_too_long', { count: 80 }) },
  },

  teamPoints: {
    numericality: {
      greaterThan: 0,
      notValid: i18n.t('teams_list.add_team.team_points_not_number'),
      notGreaterThan: i18n.t('teams_list.add_team.team_points_greater_than_zero'),
    },
    length: {
      maximum: 10,
      message: i18n.t('teams_list.add_team.team_points_too_long', { count: 10 }),
    },
  },

  playerName: {
    presence: { message: i18n.t('common_error_messages.player_name_required') },
    length: { maximum: 50, tooLong: i18n.t('common_error_messages.player_name_too_long', { count: 50 }) },
  },

  gameNumber: {
    presence: { message: i18n.t('common_error_messages.game_number_required') },
    format: { pattern: '^[0-9]*$', message: i18n.t('common_error_messages.game_number_only_numbers') },
  },

  optionalGameNumber: {
    format: { pattern: '^[0-9]*$', message: i18n.t('add_delay.game_number_only_numbers') },
  },

  score: {
    format: { pattern: '^[0-9]*$', message: i18n.t('add_update_game.input_only_numbers') },
  },

  delayMinutes: {
    presence: { message: i18n.t('add_delay.delay_minutes_required') },
    format: { pattern: '^[0-9-]*$', message: i18n.t('add_delay.delay_minutes_only_numbers') },
  },

  notificationMessage: {
    presence: { message: i18n.t('send_notification.message_required') },
    length: { maximum: 200, tooLong: i18n.t('send_notification.message_too_long', { count: 200 }) },
  },

  registrationTax: {
    length: { maximum: 7, tooLong: i18n.t('registration.registration_info.tax_too_long', { count: 7 }) },
  },

  requiredCustomRegistrationField: {
    presence: { message: i18n.t('common_error_messages.no_empty_field'), allowEmpty: false },
    length: {
      maximum: 100,
      tooLong: i18n.t('teams_list.update_team.registration_field_too_long', { count: 100 }),
    },
  },

  optionalCustomRegistrationField: {
    length: {
      maximum: 100,
      tooLong: i18n.t('teams_list.update_team.registration_field_too_long', { count: 100 }),
    },
  },

  name: {
    presence: { message: i18n.t('common_error_messages.name_required') },
    length: { maximum: 60, tooLong: i18n.t('common_error_messages.name_too_long', { count: 60 }) },
  },

  email: {
    presence: { message: i18n.t('common_error_messages.email_required') },
    email: { message: i18n.t('common_error_messages.email_invalid') },
  },

  password: {
    presence: { message: i18n.t('sign_up.password_required') },
    length: {
      minimum: 6,
      tooShort: i18n.t('sign_up.password_too_short', { count: 6 }),
      maximum: 60,
      tooLong: i18n.t('sign_up.password_too_long', { count: 60 }),
    },
  },

  contact: {
    name: {
      presence: { message: i18n.t('common_error_messages.name_required') },
      length: { maximum: 60, tooLong: i18n.t('common_error_messages.name_too_long', { count: 60 }) },
    },

    message: {
      presence: { message: i18n.t('contact.message_required') },
      length: { maximum: 60, tooLong: i18n.t('contact.message_too_long', { count: 400 }) },
    },
  },
};
