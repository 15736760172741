import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import UpdateIcon from '@mui/icons-material/Update';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import PricingPlans from 'Components/Atomic/PricingPlans/PricingPlans';
import SvgSizer from 'Components/Atomic/SvgSizer/SvgSizer';
import StatusUpdate from 'Assets/Illustrations/status_update.svg';
import GrowthCurve from 'Assets/Illustrations/growth_curve.svg';
import HappyNews from 'Assets/Illustrations/happy_news.svg';
import Building from 'Assets/Illustrations/building.svg';
import Mobile from 'Assets/Illustrations/mobile.svg';
import LandingPhoto from 'Assets/Images/landing.png';
import LandingMobile2Photo from 'Assets/Images/landing-mobile-2.png';
import LandingMobile3Photo from 'Assets/Images/landing-mobile-3.png';
import LandingDesktop from 'Assets/Images/landing-desktop.png';
import LandingDesktop2 from 'Assets/Images/landing-desktop-2.jpg';
import { FirstLevelRoutes } from 'Routing/route-paths';
import classes from './styles';

const LandingPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const renderMainContainer = () => {
    return (
      <Container sx={classes.mainContainer}>
        <Typography variant="h1" sx={classes.title}>
          <span>{t('landing.title')}</span> <span style={classes.titleSecondPart}>{t('landing.title_second_part')}</span>
        </Typography>

        <Typography variant="h3" sx={classes.subtitle}>
          {t('landing.subtitle')}
        </Typography>

        <Button sx={classes.getStartedButton} onClick={() => navigate(`../${FirstLevelRoutes.CREATE_ACCOUNT}`)}>
          <Typography sx={classes.getStartedButtonText}>{t('landing.get_started')}</Typography>
        </Button>

        <Box sx={classes.mainContainerImages}>
          <img loading="lazy" src={LandingPhoto} width="100%" />

          <img loading="lazy" src={LandingMobile2Photo} width="100%" />
        </Box>
      </Container>
    );
  };

  const renderFeaturesContainer = () => {
    return (
      <Container sx={{ ...classes.section, ...classes.centeredSection }}>
        <Box sx={{ ...classes.chip, ...classes.primaryChip }}>
          <Typography variant="h4">{t('landing.features_label')}</Typography>
        </Box>

        <Typography variant="h1" sx={{ ...classes.sectionTitle, ...classes.sectionTitlePadding }}>
          {t('landing.features_title')}
        </Typography>

        <Typography sx={{ ...classes.sectionSubtitle, ...classes.sectionSubtitlePadding }}>{t('landing.features_subtitle')}</Typography>

        <Grid container gap={3}>
          <Grid item xs={12} md sx={classes.sectionCard}>
            <SvgSizer component={StatusUpdate} xs="140px" sm="140px" md="160px" />

            <Typography variant="h2" sx={classes.sectionCardTitle}>
              {t('landing.features_one_title')}
            </Typography>

            <Typography> {t('landing.features_one_subtitle')}</Typography>
          </Grid>

          <Grid item xs={12} md sx={classes.sectionCard}>
            <SvgSizer component={HappyNews} xs="90px" sm="90px" md="90px" />

            <Typography variant="h2" sx={classes.sectionCardTitle}>
              {t('landing.features_two_title')}
            </Typography>

            <Typography> {t('landing.features_two_subtitle')}</Typography>
          </Grid>

          <Grid item xs={12} md sx={classes.sectionCard}>
            <SvgSizer component={GrowthCurve} xs="140px" sm="140px" md="160px" />

            <Typography variant="h2" sx={classes.sectionCardTitle}>
              {t('landing.features_three_title')}
            </Typography>

            <Typography> {t('landing.features_three_subtitle')}</Typography>
          </Grid>
        </Grid>
      </Container>
    );
  };

  const renderWebAppInfoContainer = () => {
    return (
      <Container sx={classes.section}>
        <Grid container display="flex" alignItems="center" flexDirection={{ xs: 'column-reverse', md: 'row' }}>
          <Grid item xs={12} md={6} display="flex" flexDirection="column" justifyContent="center">
            <Box sx={{ ...classes.chip, ...classes.secondaryChip }}>
              <Typography variant="h4">{t('landing.web_app_chip')}</Typography>
            </Box>

            <Typography variant="h1" sx={classes.sectionTitle}>
              {t('landing.web_app_title')}
            </Typography>

            <Typography sx={classes.sectionSubtitle}>{t('landing.web_app_subtitle')}</Typography>

            <Box sx={classes.checkRow}>
              <UpdateIcon />

              <Box>
                <Typography variant="h3">{t('landing.web_app_checkrow_one_title')}</Typography>

                <Typography sx={classes.checkrowSubtitle}>{t('landing.web_app_checkrow_one_subtitle')}</Typography>
              </Box>
            </Box>

            <Box sx={classes.checkRow}>
              <CloseIcon />

              <Box>
                <Typography variant="h3">{t('landing.web_app_checkrow_two_title')}</Typography>

                <Typography sx={classes.checkrowSubtitle}>{t('landing.web_app_checkrow_two_subtitle')}</Typography>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12} md={6} sx={classes.webAppInfoSectionImageContainer}>
            <div>
              <img loading="lazy" src={LandingDesktop} style={{ width: '100%', height: 'auto' }} />
            </div>
          </Grid>
        </Grid>
      </Container>
    );
  };

  const renderMobileAppInfoContainer = () => {
    return (
      <Container sx={classes.section}>
        <Grid container>
          <Grid item xs={12} md sx={classes.mobileAppInfoSectionImageContainer}>
            <img loading="lazy" src={LandingMobile3Photo} />
          </Grid>

          <Grid item xs={12} md display="flex" flexDirection="column" justifyContent="center">
            <Box sx={{ ...classes.chip, ...classes.thirdChip }}>
              <Typography variant="h4">{t('landing.mobile_app_chip')}</Typography>
            </Box>

            <Typography variant="h1" sx={classes.sectionTitle}>
              {t('landing.mobile_app_title')}
            </Typography>

            <Typography sx={classes.sectionSubtitle}>{t('landing.mobile_app_subtitle')}</Typography>
          </Grid>
        </Grid>
      </Container>
    );
  };

  const renderHowToStartStepper = () => {
    return (
      <Container sx={{ ...classes.section, ...classes.centeredSection }}>
        <Box sx={{ ...classes.chip, ...classes.fourthChip }}>
          <Typography variant="h4">{t('landing.steps_chip')}</Typography>
        </Box>

        <Typography variant="h1" sx={{ ...classes.sectionTitle, ...classes.sectionTitlePadding }}>
          {t('landing.steps_title')}
        </Typography>

        <Grid container gap={3}>
          <Grid item xs={12} md display="flex" flexDirection="column" alignItems="center">
            <Box sx={{ ...classes.sectionCard, ...classes.howItWorksSectionCard }}>
              <img loading="lazy" src={LandingDesktop2} width="250px" />
            </Box>

            <Box sx={classes.stepCount}>
              <Typography>1</Typography>
            </Box>

            <Box sx={classes.sectionTextContainer}>
              <Typography variant="h3" sx={classes.sectionStepTitle}>
                {t('landing.step_one_title')}
              </Typography>

              <Typography> {t('landing.step_one_description')}</Typography>
            </Box>
          </Grid>

          <Grid item xs={12} md display="flex" flexDirection="column" alignItems="center">
            <Box sx={{ ...classes.sectionCard, ...classes.howItWorksSectionCard }}>
              <SvgSizer component={Building} xs="200px" sm="200px" md="200px" />
            </Box>

            <Box sx={classes.stepCount}>
              <Typography>2</Typography>
            </Box>

            <Box sx={classes.sectionTextContainer}>
              <Typography variant="h3" sx={classes.sectionStepTitle}>
                {t('landing.step_two_title')}
              </Typography>

              <Typography> {t('landing.step_two_description')}</Typography>
            </Box>
          </Grid>

          <Grid item xs={12} md display="flex" flexDirection="column" alignItems="center">
            <Box sx={{ ...classes.sectionCard, ...classes.howItWorksSectionCard }}>
              <SvgSizer component={Mobile} xs="200px" sm="200px" md="120px" />
            </Box>

            <Box sx={classes.stepCount}>
              <Typography>3</Typography>
            </Box>

            <Box sx={classes.sectionTextContainer}>
              <Typography variant="h3" sx={classes.sectionStepTitle}>
                {t('landing.step_three_title')}
              </Typography>

              <Typography> {t('landing.step_three_description')}</Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    );
  };

  const renderPricingPlans = () => {
    return (
      <Container sx={{ ...classes.section, ...classes.centeredSection }}>
        <Box sx={{ ...classes.chip, ...classes.fifthhChip }}>
          <Typography variant="h4">{t('landing.pricing_chip')}</Typography>
        </Box>

        <Typography variant="h1" sx={{ ...classes.sectionTitle, ...classes.sectionTitlePadding }}>
          {t('landing.pricing_title')}
        </Typography>

        <PricingPlans />
      </Container>
    );
  };

  return (
    <Box>
      {renderMainContainer()}

      {renderFeaturesContainer()}

      {renderWebAppInfoContainer()}

      {renderMobileAppInfoContainer()}

      {renderHowToStartStepper()}

      {renderPricingPlans()}
    </Box>
  );
};

export default LandingPage;
