import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { PricingPlansIds, pricingPlansOptions } from 'Models/Pricing';
import { FirstLevelRoutes } from 'Routing/route-paths';
import landingClasses from 'Pages/LandingPage/styles';
import classes from './styles';

const PricingPlans = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const mediumPlan = pricingPlansOptions.find(p => p.id === PricingPlansIds.MEDIUM)!;
  const largePlan = pricingPlansOptions.find(p => p.id === PricingPlansIds.LARGE)!;
  const xLargePlan = pricingPlansOptions.find(p => p.id === PricingPlansIds.X_LARGE)!;

  const navigateToGetStarted = () => {
    navigate(`../${FirstLevelRoutes.CREATE_ACCOUNT}`);
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6} lg={3}>
        <Box sx={{ ...landingClasses.sectionCard, ...classes.sectionCardPricing }}>
          <Typography sx={classes.sectionCardPricingLabel}>{t('pricing.small_size')}</Typography>

          <Typography variant="h1">{t('pricing.small_price')}</Typography>

          <List sx={classes.list}>
            <ListItem sx={classes.listItem}>{t('pricing.max_teams', { count: 8 })}</ListItem>

            <ListItem sx={classes.listItem}>{t('pricing.private_only')}</ListItem>
          </List>

          <Box sx={classes.bottomBoxContainer}>
            <Typography sx={classes.unlimitedAccessText}>{t('pricing.unlimited_access')}</Typography>

            <Button sx={classes.getStartedButtonPricing} onClick={navigateToGetStarted}>
              {t('landing.get_started')}
            </Button>
          </Box>
        </Box>
      </Grid>

      <Grid item xs={12} sm={6} lg={3}>
        <Box sx={{ ...landingClasses.sectionCard, ...classes.sectionCardPricing }}>
          <Typography sx={classes.sectionCardPricingLabel}>{t('pricing.medium_size')}</Typography>

          <Box sx={classes.offerRow}>
            <Typography sx={classes.offerOriginalPrice}>&euro;{mediumPlan.oldPrice}</Typography>

            <Typography variant="h1">&euro;{mediumPlan.currentPrice}</Typography>
          </Box>

          <List sx={classes.list}>
            <ListItem sx={classes.listItem}>{t('pricing.max_teams', { count: 16 })}</ListItem>

            <ListItem sx={classes.listItem}>{t('pricing.private_or_public')}</ListItem>
          </List>

          <Box sx={classes.bottomBoxContainer}>
            <Typography sx={classes.unlimitedAccessText}>{t('pricing.unlimited_access')}</Typography>

            <Button sx={classes.getStartedButtonPricing} onClick={navigateToGetStarted}>
              {t('landing.get_started')}
            </Button>
          </Box>
        </Box>
      </Grid>

      <Grid item xs={12} sm={6} lg={3}>
        <Box sx={{ ...landingClasses.sectionCard, ...classes.sectionCardPricing }}>
          <Typography sx={classes.sectionCardPricingLabel}>{t('pricing.large_size')}</Typography>

          <Box sx={classes.offerRow}>
            <Typography sx={classes.offerOriginalPrice}>&euro;{largePlan.oldPrice}</Typography>

            <Typography variant="h1">&euro;{largePlan.currentPrice}</Typography>
          </Box>

          <List sx={classes.list}>
            <ListItem sx={classes.listItem}>{t('pricing.max_teams', { count: 24 })}</ListItem>

            <ListItem sx={classes.listItem}>{t('pricing.private_or_public')}</ListItem>
          </List>

          <Box sx={classes.bottomBoxContainer}>
            <Typography sx={classes.unlimitedAccessText}>{t('pricing.unlimited_access')}</Typography>

            <Button sx={classes.getStartedButtonPricing} onClick={navigateToGetStarted}>
              {t('landing.get_started')}
            </Button>
          </Box>
        </Box>
      </Grid>

      <Grid item xs={12} sm={6} lg={3}>
        <Box sx={{ ...landingClasses.sectionCard, ...classes.sectionCardPricing }}>
          <Typography sx={classes.sectionCardPricingLabel}>{t('pricing.x_large_size')}</Typography>

          <Box sx={classes.offerRow}>
            <Typography sx={classes.offerOriginalPrice}>&euro;{xLargePlan.oldPrice}</Typography>

            <Typography variant="h1">&euro;{xLargePlan.currentPrice}</Typography>
          </Box>

          <List sx={classes.list}>
            <ListItem sx={classes.listItem}>{t('pricing.unlimited_teams')}</ListItem>

            <ListItem sx={classes.listItem}>{t('pricing.private_or_public')}</ListItem>
          </List>

          <Box sx={classes.bottomBoxContainer}>
            <Typography sx={classes.unlimitedAccessText}>{t('pricing.unlimited_access')}</Typography>

            <Button sx={classes.getStartedButtonPricing} onClick={navigateToGetStarted}>
              {t('landing.get_started')}
            </Button>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default PricingPlans;
