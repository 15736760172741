import { useEffect } from 'react';

import { Outlet, useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

import NavigationBar from 'Components/Atomic/NavigationBar/NavigationBar';
import Footer from 'Components/Atomic/Footer/Footer';
import CookieConsent from 'Components/Atomic/CookieConsent/CookieConsent';
import { useAppSelector } from 'Store/store';
import { FirstLevelRoutes } from 'Routing/route-paths';

const Layout = () => {
  const location = useLocation();
  const shouldHideFooter =
    location.pathname.includes(FirstLevelRoutes.TERMS_AND_CONDITIONS) ||
    location.pathname.includes(FirstLevelRoutes.PRIVACY_POLICY) ||
    location.pathname.includes(FirstLevelRoutes.COOKIE_POLICY) ||
    location.pathname.includes(FirstLevelRoutes.CREATE_ACCOUNT) ||
    location.pathname.includes(FirstLevelRoutes.SIGN_IN);
  const userStore = useAppSelector(state => state.user);
  const isPathThatNeedsToAwaitUser =
    location.pathname.includes(FirstLevelRoutes.USER_DASHBOARD) ||
    location.pathname.includes(FirstLevelRoutes.CREATE_ACCOUNT) ||
    location.pathname.includes(FirstLevelRoutes.SIGN_IN);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  if (userStore.isLoading && isPathThatNeedsToAwaitUser) {
    return (
      <Box display="flex" flex={1} alignItems="center" justifyContent="center">
        <CircularProgress size={56} thickness={4} />
      </Box>
    );
  }

  return (
    <>
      <NavigationBar />

      {/* Header size */}
      <Box flex={1} marginTop="89px">
        <Outlet />
      </Box>

      <CookieConsent />

      {!shouldHideFooter && <Footer />}
    </>
  );
};

export default Layout;
