import { HTMLAttributes, useEffect, useMemo, useRef, useState } from 'react';

import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import parse from 'autosuggest-highlight/parse';
import debounce from '@mui/material/utils/debounce';
import Paper, { PaperProps } from '@mui/material/Paper';
import { useTranslation } from 'react-i18next';

import CustomTextField from '../CustomTextField/CustomTextField';
import GoogleLogo from './google_on_white.png';
import { logError } from 'Utils/loggingUtils';
import classes from './styles';

type Props = {
  value: google.maps.places.AutocompletePrediction | null;
  errorMessage: string;
  onValueChange: (value: google.maps.places.AutocompletePrediction | null) => void;
  onTimezoneChange: (country: string, timezone: string) => void;
};

const AutocompletePlaceSearch = (props: Props) => {
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState<readonly google.maps.places.AutocompletePrediction[]>([]);
  const autocompleteService = useRef<google.maps.places.AutocompleteService | null>(null);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    setErrorMessage(props.errorMessage);
  }, [props.errorMessage]);

  useEffect(() => {
    let active = true;

    if (!autocompleteService.current && google) {
      autocompleteService.current = new google.maps.places.AutocompleteService();
    }

    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === '') {
      setOptions(props.value ? [props.value] : []);
      return undefined;
    }

    getPredictions({ input: inputValue }, (results?: readonly google.maps.places.AutocompletePrediction[] | null) => {
      if (active) {
        let newOptions: readonly google.maps.places.AutocompletePrediction[] = [];

        if (props.value) {
          newOptions = [props.value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [props.value, inputValue, fetch]);

  const getPredictions = useMemo(
    () =>
      debounce((request: { input: string }, callback: (results?: readonly google.maps.places.AutocompletePrediction[] | null) => void) => {
        autocompleteService.current?.getPlacePredictions(request, callback);
      }, 400),
    [],
  );

  const handlePlaceSelected = async (placeId?: string, location?: string) => {
    if (!placeId) {
      setErrorMessage(t('dashboard.form.location_error_message'));
      return;
    }

    try {
      await new google.maps.places.PlacesService(document.createElement('div')).getDetails(
        {
          placeId,
          fields: ['address_components', 'geometry.location'],
        },
        async result => {
          if (result && result.address_components && result.geometry) {
            const latitude = result.geometry.location?.lat();
            const longitude = result.geometry.location?.lng();
            const timestamp = Math.floor(Date.now() / 1000);
            let state: string = '';
            let country: string = '';

            for (const component of result.address_components) {
              if (component.types.includes('administrative_area_level_1')) {
                state = component.long_name;
              } else if (component.types.includes('country')) {
                country = component.long_name;
              }
            }

            // Check if only state & county is slected or if only country/continent is selected
            if ((state && country && result.address_components.length === 2) || result.address_components.length === 1) {
              setErrorMessage(t('dashboard.form.location_too_general'));
              props.onTimezoneChange('', '');
              return;
            }

            const url = `https://maps.googleapis.com/maps/api/timezone/json?location=${latitude},${longitude}&timestamp=${timestamp}&key=${process.env.REACT_APP_FIREBASE_API_KEY}`;
            const response = await fetch(url);
            const data = await response.json();

            props.onTimezoneChange(country, data.timeZoneId);
          } else {
            setErrorMessage(t('dashboard.form.location_error_message'));
          }
        },
      );
    } catch (error) {
      logError('Get place details and timezone', error);
      setErrorMessage(t('dashboard.form.location_error_message'));
    }
  };

  const handleValueChange = (newValue: google.maps.places.AutocompletePrediction | null) => {
    setErrorMessage('');
    setOptions(newValue ? [newValue, ...options] : options);
    props.onValueChange(newValue);

    if (newValue) {
      handlePlaceSelected(newValue?.place_id, newValue?.description);
    } else {
      props.onValueChange(null);
    }
  };

  const renderOptionsDropdown = (props: PaperProps) => {
    return (
      <Paper {...props}>
        {props.children}

        <Box sx={classes.optionsDropdownContainer}>
          <Typography variant="body2">{t('dashboard.form.location_google_text')}&nbsp;</Typography>

          <img src={GoogleLogo} alt="Google logo" />
        </Box>
      </Paper>
    );
  };

  const renderOption = (props: HTMLAttributes<HTMLLIElement>, option: google.maps.places.AutocompletePrediction) => {
    const matches = option.structured_formatting.main_text_matched_substrings || [];

    const parts = parse(
      option.structured_formatting.main_text,
      matches.map(match => [match.offset, match.offset + match.length]),
    );

    return (
      <li {...props} key={option.place_id}>
        <Grid container alignItems="center">
          <Grid item sx={{ display: 'flex', width: 44 }}>
            <LocationOnIcon sx={{ color: 'text.secondary' }} />
          </Grid>

          <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
            {parts.map((part, index) => (
              <Box key={index} component="span" sx={{ fontWeight: part.highlight ? 'bold' : 'regular' }}>
                {part.text}
              </Box>
            ))}

            <Typography variant="body2" color="text.secondary">
              {option.structured_formatting.secondary_text}
            </Typography>
          </Grid>
        </Grid>
      </li>
    );
  };

  return (
    <Autocomplete
      getOptionLabel={option => (typeof option === 'string' ? option : option.description)}
      filterOptions={x => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={props.value}
      noOptionsText={t('dashboard.form.location_no_results')}
      PaperComponent={renderOptionsDropdown}
      renderInput={params => (
        <CustomTextField
          {...params}
          required
          label={t('dashboard.form.location')}
          placeholder={t('dashboard.form.location_placeholder')}
          error={!!errorMessage}
          helperText={errorMessage}
        />
      )}
      renderOption={renderOption}
      onChange={(_, newValue: google.maps.places.AutocompletePrediction | null) => handleValueChange(newValue)}
      onInputChange={(_, newInputValue) => setInputValue(newInputValue)}
    />
  );
};

export default AutocompletePlaceSearch;
