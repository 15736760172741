import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { SxProps } from '@mui/material/styles';

import classes from './styles';

type Props = {
  label: string;
  sublabel?: string;
  inputContainer?: SxProps;
} & TextFieldProps;

const CustomTextField = (props: Props) => {
  const { label, sublabel, inputContainer, ...textFieldProps } = props;

  return (
    <Box sx={inputContainer}>
      <Typography sx={classes.label}>{label}</Typography>

      <Typography sx={classes.sublabel}>{sublabel}</Typography>

      <TextField sx={classes.input} {...textFieldProps} />
    </Box>
  );
};

export default CustomTextField;
