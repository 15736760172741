import { useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';

import FrequentlyAskedQuestionItem from './FrequentlyAskedQuestionItem/FrequentlyAskedQuestionItem';

const FrequentlyAskedQuestions = () => {
  const { t } = useTranslation();
  const items = useMemo(
    () => [
      { question: t('faq.question_four'), answer: t('faq.answer_four') },
      { question: t('faq.question_three'), answer: t('faq.answer_three') },
      { question: t('faq.question_one'), answer: t('faq.answer_one') },
      { question: t('faq.question_two'), answer: t('faq.answer_two') },
      { question: t('faq.question_five'), answer: t('faq.answer_five') },
      { question: t('faq.question_six'), answer: t('faq.answer_six') },
      { question: t('faq.question_seven'), answer: t('faq.answer_seven') },
      { question: t('faq.question_eight'), answer: t('faq.answer_eight') },
    ],
    [],
  );

  return (
    <Grid container marginTop={{ xs: 1, sm: 3 }} spacing={2}>
      {items.map(i => (
        <Grid key={i.question} item xs={12} sm={6}>
          <FrequentlyAskedQuestionItem question={i.question} answer={i.answer} />
        </Grid>
      ))}
    </Grid>
  );
};

export default FrequentlyAskedQuestions;
