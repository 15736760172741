import colors from 'Styles/colors';

const classes = {
  mainContainer: {
    paddingLeft: { xs: '24px!important', sm: '88px!important', md: '128px!important', lg: '224px!important' },
    paddingRight: { xs: '24px!important', sm: '88px!important', md: '128px!important', lg: '224px!important' },
  },

  subtitle: {
    paddingLeft: { xs: '8px!important', sm: '32px!important', md: '56px!important', lg: '64px!important' },
    paddingRight: { xs: '8px!important', sm: '32px!important', md: '56px!important', lg: '64px!important' },
  },

  pricingPlansContainer: {
    marginTop: { xs: '8px', sm: '24px', md: '40px', lg: '56px' },
  },

  featuresContainer: {
    marginTop: '8px',
    textAlign: 'left',
  },

  featureTitleRow: {
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
    marginBottom: '16px',
  },

  iconContainer: {
    backgroundColor: colors.LIGHT_GREY,
    padding: '4px',
    borderRadius: '50px',
    boxShadow: '0 18px 35px -5px rgb(17 18 19 / 4%)',
    fontSize: 0,
  },
};

export default classes;
