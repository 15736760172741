import validate from 'validate.js';

import useCustomReducer from './useCustomReducer';

interface ValidateInput {
  name: string;
  constraints: Record<string, any>;
}

const useFormReducer = <S extends Object>(initialState: S) => {
  const [state, dispatch] = useCustomReducer<S>(initialState);

  const getErorMessage = (value: string, constraints: Record<string, any>) => {
    // Checking for value because if it's "" (empty string), validate will say it's valid
    const result = validate.single(value ? value : null, constraints);

    return result ? result[0] : '';
  };

  const getFieldData = (value: string, constraints: Record<string, any>) => {
    const errorMessage = getErorMessage(value, constraints);

    return { value, isValid: !errorMessage, errorMessage };
  };

  const handleValueChange = (name: string, value: string, constraints: Record<string, any>) => {
    const isValid = !getErorMessage(value, constraints);

    dispatch({ ...state, [name]: { value, isValid, errorMessage: '' } });
  };

  const getAreAllFieldsValid = () => {
    const keys = Object.keys(state);
    const invalidFieldFound = keys.find(field => (state as any)[field].isValid === false);

    return invalidFieldFound ? false : true;
  };

  const validateInputs = (validationInputs: ValidateInput[]) => {
    let updatedState: Partial<S> = { ...state };

    validationInputs.forEach(input => {
      const value: string = (state as any)[input.name].value;
      const fieldData = getFieldData(value, input.constraints);

      updatedState = { ...updatedState, [input.name]: fieldData };
    });

    dispatch({ ...updatedState });
  };

  const updateFormState = (state: Partial<S>) => {
    dispatch(state);
  };

  return [state, handleValueChange, getAreAllFieldsValid, validateInputs, updateFormState] as [
    S,
    (name: string, value: string, constraints: Record<string, any>) => void,
    () => boolean,
    (validationInputs: ValidateInput[]) => void,
    (state: Partial<S>) => void,
  ];
};

export default useFormReducer;
