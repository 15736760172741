import { collection, query, doc, getDocs, addDoc, updateDoc, deleteDoc, Timestamp, writeBatch, orderBy } from 'firebase/firestore';

import { firestore } from 'Services/firebase';
import { RegisteredTeam, AddRegisteredTeamParams, GetRegisteredTeamParams as GetRegisteredTeam } from 'Models/RegisteredTeam';
import { convertDateToUtc } from 'Utils/dateUtils';
import { TeamPoints } from 'Pages/OrganizeTournament/RegisteredTeams/EditTeamPointsDialog/models';

export const getAllTeamsCall = async (tournamentId: string): Promise<RegisteredTeam[]> => {
  const subColRef = collection(firestore, 'Tournament', tournamentId, 'Teams');
  const q = query(subColRef, orderBy('points', 'desc'));
  const response = await getDocs(q);

  const teams = response.docs.map(doc => {
    const data = doc.data() as GetRegisteredTeam;

    return {
      ...data,
      id: doc.id,
      addedDateUtc: convertDateToUtc(new Timestamp(data.addedDate?.seconds, data.addedDate?.nanoseconds).toDate()),
    } as RegisteredTeam;
  });

  return teams;
};

export const updateTeamCall = async (tournamentId: string, team: RegisteredTeam) => {
  const updatedTeam: Partial<RegisteredTeam> = { ...team };
  const docRef = doc(firestore, `Tournament/${tournamentId}/Teams`, team.id);

  delete updatedTeam.id;
  await updateDoc(docRef, updatedTeam);
};

export const deleteTeamCall = async (tournamentId: string, teamId: string) => {
  const docRef = doc(firestore, `Tournament/${tournamentId}/Teams`, teamId);

  await deleteDoc(docRef);
};

export const addRegisteredTeamCall = async (tournamentId: string, team: AddRegisteredTeamParams) => {
  const doc = await addDoc(collection(firestore, `Tournament/${tournamentId}/Teams`), team);

  return doc.id;
};

export const bulkEditTeamPointsCall = async (tournamentId: string, teamPoints: TeamPoints[]) => {
  const batch = writeBatch(firestore);

  teamPoints.forEach(teamPoint => {
    batch.update(doc(firestore, `Tournament/${tournamentId}/Teams`, teamPoint.teamId), { points: +teamPoint.points ?? 0 });
  });

  await batch.commit();
};
