import { doc, getDoc } from 'firebase/firestore';

import { firestore } from 'Services/firebase';
import { TournamentPrivateSettings } from 'Models/TournamentPrivate';

export const getPrivateSettingsCall = async (tournamentId: string) => {
  const docRef = doc(firestore, `Tournament/${tournamentId}/Private/Settings`);
  const response = (await getDoc(docRef)).data() as TournamentPrivateSettings;

  return response;
};
