import { ActionReducerMapBuilder } from '@reduxjs/toolkit';

import * as TeamsActions from './actions';
import { TeamsState } from './state';

const extraReducers = (builder: ActionReducerMapBuilder<TeamsState>) => {
  getAllRegisteredTeams(builder);
  addRegisteredTeam(builder);
  deleteRegisteredTeam(builder);
  updateRegisteredTeam(builder);
  updateRegisteredTeamTaxPaid(builder);
  bulkEditTeamPoints(builder);
};

const getAllRegisteredTeams = (builder: ActionReducerMapBuilder<TeamsState>) => {
  builder
    .addCase(TeamsActions.getAllRegisteredTeams.pending, state => {
      state.isLoading = true;
    })
    .addCase(TeamsActions.getAllRegisteredTeams.fulfilled, (state, action) => {
      let teams = { ...state.registeredTeams };
      action.payload.allCategories.forEach(category => {
        teams[category] = action.payload.teams.filter(t => t.category === category);
      });

      state.registeredTeams = teams;
      state.isLoading = false;
    })
    .addCase(TeamsActions.getAllRegisteredTeams.rejected, (state, ac) => {
      state.isLoading = false;
    });
};

const addRegisteredTeam = (builder: ActionReducerMapBuilder<TeamsState>) => {
  builder
    .addCase(TeamsActions.addRegisteredTeam.pending, state => {
      state.isAdding = true;
    })
    .addCase(TeamsActions.addRegisteredTeam.fulfilled, (state, action) => {
      const index = state.registeredTeams[action.payload.team.category]?.findIndex(t => action.payload.team.points > t.points);

      if (index === -1) {
        state.registeredTeams[action.payload.team.category]?.push(action.payload.team);
      } else {
        state.registeredTeams[action.payload.team.category]?.splice(index ?? 0, 0, action.payload.team);
      }

      state.isAdding = false;
    })
    .addCase(TeamsActions.addRegisteredTeam.rejected, state => {
      state.isAdding = false;
    });
};

const deleteRegisteredTeam = (builder: ActionReducerMapBuilder<TeamsState>) => {
  builder
    .addCase(TeamsActions.deleteRegisteredTeam.pending, state => {
      state.isDeleting = true;
    })
    .addCase(TeamsActions.deleteRegisteredTeam.fulfilled, (state, action) => {
      state.registeredTeams[action.payload.category] = state.registeredTeams[action.payload.category]?.filter(
        t => t.id !== action.payload.teamId,
      );
      state.isDeleting = false;
    })
    .addCase(TeamsActions.deleteRegisteredTeam.rejected, state => {
      state.isDeleting = false;
    });
};

const updateRegisteredTeam = (builder: ActionReducerMapBuilder<TeamsState>) => {
  builder
    .addCase(TeamsActions.updateRegisteredTeam.pending, state => {
      state.isUpdating = true;
    })
    .addCase(TeamsActions.updateRegisteredTeam.fulfilled, (state, action) => {
      const updatedTeams = [...state.registeredTeams[action.payload.category]!];
      const selectedTeamIndex = state.registeredTeams[action.payload.category]?.findIndex(t => t.id === action.payload.updatedTeam.id);

      updatedTeams?.splice(selectedTeamIndex!, 1, action.payload.updatedTeam);
      state.registeredTeams[action.payload.category] = updatedTeams;

      state.isUpdating = false;
    })
    .addCase(TeamsActions.updateRegisteredTeam.rejected, state => {
      state.isUpdating = false;
    });
};

const updateRegisteredTeamTaxPaid = (builder: ActionReducerMapBuilder<TeamsState>) => {
  builder.addCase(TeamsActions.updateRegisteredTeamTaxPaid.fulfilled, (state, action) => {
    const updatedTeams = [...state.registeredTeams[action.payload.category]!];
    const selectedTeamIndex = state.registeredTeams[action.payload.category]?.findIndex(t => t.id === action.payload.updatedTeam.id);

    updatedTeams?.splice(selectedTeamIndex!, 1, action.payload.updatedTeam);
    state.registeredTeams[action.payload.category] = updatedTeams;

    state.teamIdsDisabledCheckbox = state.teamIdsDisabledCheckbox.filter(id => id !== action.payload.updatedTeam.id);
  });
};

const bulkEditTeamPoints = (builder: ActionReducerMapBuilder<TeamsState>) => {
  builder
    .addCase(TeamsActions.bulkEditTeamPoints.pending, state => {
      state.isUpdatingPoints = true;
    })
    .addCase(TeamsActions.bulkEditTeamPoints.fulfilled, (state, action) => {
      const updatedTeams = state.registeredTeams[action.payload.category]?.map(t => {
        const selectedTeamIndex = action.payload.teamPoints.findIndex(tp => tp.teamId === t.id);

        if (selectedTeamIndex !== -1) {
          return { ...t, points: +action.payload.teamPoints[selectedTeamIndex].points };
        } else {
          return t;
        }
      });

      updatedTeams?.sort((a, b) => +b.points - +a.points);
      state.registeredTeams[action.payload.category] = updatedTeams;
      state.isUpdatingPoints = false;
    })
    .addCase(TeamsActions.bulkEditTeamPoints.rejected, state => {
      state.isUpdatingPoints = false;
    });
};

export default extraReducers;
