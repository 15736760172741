import colors from 'Styles/colors';
import fonts from 'Styles/fonts';

const classes = {
  sectionCardPricing: {
    alignItems: 'flex-start',
    height: '100%',
  },

  sectionCardPricingLabel: {
    color: colors.MEDIUM_GREY,
  },

  sectionCardTitle: {
    marginTop: '40px',
    marginBottom: '24px',
  },

  sectionCardPricingSubtitle: {
    marginTop: '32px',
    fontSize: fonts.fontSize.m,
    color: colors.MEDIUM_GREY,
  },

  list: {
    listStyleType: 'disc',
    marginTop: '32px',
    marginLeft: '16px',
    marginBottom: '32px',
  },

  listItem: {
    display: 'list-item',
    fontFamily: fonts.fontFamily.SemiBold,
    fontSize: fonts.fontSize.m,
    marginTop: '8px',
    padding: 0,
  },

  getStartedButtonPricing: {
    width: '100%',
    borderRadius: '24px',
    alignSelf: { xs: 'stretch', sm: 'flex-start', md: 'stretch' },
  },

  offerRow: {
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
  },

  offerOriginalPrice: {
    fontFamily: fonts.fontFamily.SemiBold,
    fontSize: fonts.fontSize.m,
    textDecoration: 'line-through',
    color: colors.MEDIUM_GREY,
  },

  bottomBoxContainer: {
    marginTop: 'auto',
    width: '100%',
  },

  unlimitedAccessText: {
    fontSize: 12,
    marginBottom: '8px',
    color: colors.MEDIUM_GREY,
    textAlign: 'center',
  },
};

export default classes;
