import { createSlice } from '@reduxjs/toolkit';

import { userInitialState } from './state';
import { resetUserReducer } from './reducers';
import extraReducers from './extraReducers';

const userSlice = createSlice({
  name: 'user',
  initialState: userInitialState,
  reducers: { resetUser: resetUserReducer },
  extraReducers: extraReducers,
});

export default userSlice.reducer;

export const { resetUser } = userSlice.actions;
