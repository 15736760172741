export enum SignInFields {
  EMAIL = 'email',
  PASSWORD = 'password',
}

export interface SignInFieldsState {
  [SignInFields.PASSWORD]: { value: string; errorMessage: string; isValid: boolean };
  [SignInFields.EMAIL]: { value: string; errorMessage: string; isValid: boolean };
}

export const signInFieldsInitialState: SignInFieldsState = {
  [SignInFields.PASSWORD]: { value: '', errorMessage: '', isValid: false },
  [SignInFields.EMAIL]: { value: '', errorMessage: '', isValid: false },
};
