import { useEffect, useState } from 'react';

import Typography from '@mui/material/Typography';
import Slide from '@mui/material/Slide';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CookieIcon from '@mui/icons-material/Cookie';
import { useTranslation } from 'react-i18next';
import { setConsent } from 'firebase/analytics';

import { getShouldShowCookieConsent, setShouldShowCookieConsent } from 'Services/LocalStorageService';
import classes from './styles';

const CookieConsent = () => {
  const [isVisible, setIsVisible] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    setTimeout(() => {
      if (getShouldShowCookieConsent()) {
        setIsVisible(true);
      }
    }, 1000);
  }, []);

  const handleAccept = () => {
    setIsVisible(false);
    setShouldShowCookieConsent();
  };

  const handleDecline = () => {
    setConsent({
      ad_storage: 'denied',
      analytics_storage: 'denied',
      functionality_storage: 'granted',
      personalization_storage: 'denied',
      security_storage: 'granted',
    });
    setIsVisible(false);
    setShouldShowCookieConsent();
  };

  return (
    <Slide direction="up" in={isVisible} mountOnEnter unmountOnExit>
      <Box sx={classes.container}>
        <Box sx={classes.leftContainer}>
          <CookieIcon />

          <Typography variant="h4">{t('cookies.title')}</Typography>
        </Box>

        <Box sx={classes.actionsContainer}>
          <Button onClick={handleAccept}>{t('common.accept')}</Button>

          <Button variant="outlined" onClick={handleDecline}>
            {t('common.decline')}
          </Button>
        </Box>
      </Box>
    </Slide>
  );
};

export default CookieConsent;
