import Grid from '@mui/material/Grid';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useTranslation } from 'react-i18next';
import { Dayjs } from 'dayjs';

import customTextFieldClasses from 'Components/Atomic/CustomTextField/styles';

type Props = {
  startDate: Dayjs;
  endDate: Dayjs;
  dateErrorMessage: string;
  onStartDateChange: (date: Dayjs) => void;
  onEndDateChange: (date: Dayjs) => void;
};

const DateRange = (props: Props) => {
  const { t } = useTranslation();

  return (
    <Grid container gap={3} marginTop={3}>
      <Grid item xs={12} sm="auto">
        <Typography sx={customTextFieldClasses.label} marginBottom="4px">
          {t('dashboard.form.start_date')}
        </Typography>

        <DatePicker
          value={props.startDate}
          inputFormat="DD/MM/YYYY"
          disablePast
          onChange={date => props.onStartDateChange(date!)}
          renderInput={(params: TextFieldProps) => (
            <TextField
              {...params}
              error={!!props.dateErrorMessage}
              helperText={props.dateErrorMessage ? props.dateErrorMessage : undefined}
              onKeyDown={e => e.preventDefault()}
            />
          )}
        />
      </Grid>

      <Grid item xs={12} sm="auto">
        <Typography sx={customTextFieldClasses.label} marginBottom="4px">
          {t('dashboard.form.end_date')}
        </Typography>

        <DatePicker
          value={props.endDate}
          inputFormat="DD/MM/YYYY"
          minDate={props.startDate}
          onChange={date => props.onEndDateChange(date!)}
          renderInput={(params: TextFieldProps) => <TextField {...params} error={false} onKeyDown={e => e.preventDefault()} />}
        />
      </Grid>
    </Grid>
  );
};

export default DateRange;
