import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);
// Converts given date to UTC
export const convertDateToUtc = (date: Date) => {
  return dayjs(date).utc().format();
};

// The Dayjs object gets first converted to tournament's timezone
// After that we can output a format
export const getFormattedDate = (date: Dayjs) => {
  return date.tz().format('DD MMMM');
};

// Adds minuted to a date. There is no reason to convert to tournament's timezone since it represents solely a speiciic point in time
// no matter if it's tournament's timezone or not
export const addMinutesToDate = (dateUtc: string, minutes: number) => {
  return dayjs(dateUtc).add(minutes, 'minute').toDate();
};

// The Dayjs object gets the UTC date and converts it to tournament's timezone
// After that we return the Dayjs object with tournament's timezone bounded to the object
export const getDayJsDateInTournamentTimezone = (dateUtc: string) => {
  return dayjs(dateUtc).tz();
};

// Checks if current date is bigger than tournament start date.
// No need to convert to tournament's timezone since both represent a point in time.
export const getHasTournamentStarted = (startDateUtc: string) => {
  const currentDate = dayjs();
  const tournamentStartDate = dayjs(startDateUtc);

  return currentDate.diff(tournamentStartDate, 'seconds') > 0;
};

// The Dayjs objects get the UTC dates and converts them to tournament's timezone
// Having the Dayjs object with tournament's timezone included we can return them
export const getTournamentDays = (startDateUtc: string, endDateUtc: string): Dayjs[] => {
  let dayJsDate = dayjs(startDateUtc).tz();
  const dayJsEndDate = dayjs(endDateUtc).tz();
  let dates = [];

  while (dayJsDate <= dayJsEndDate) {
    dates.push(dayJsDate);
    dayJsDate = dayJsDate.add(1, 'day');
  }

  return dates;
};

// A date with the year 9999 is used as a placeholder date (i.e. when games are created)
export const getIsPlaceholderDate = (timeUtc: string) => {
  return dayjs(timeUtc).year() === 9999;
};

// Return if the user uses 24 hours format
export const getLocaleUses24HourTime = () => {
  const dateParts = new Intl.DateTimeFormat(undefined, { hour: 'numeric' }).formatToParts(new Date(2020, 0, 1, 13));

  return dateParts.find(part => part.type === 'hour')?.value.length === 2;
};
