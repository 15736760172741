import * as Sentry from '@sentry/react';

export const logError = (message: string, error: any) => {
  // It is because JSON.stringify() only includes enumerable properties and the Error class doesn’t have any, hence the output of {}.
  // https://levelup.gitconnected.com/beware-of-using-json-stringify-for-logging-933f18626d51
  if (error instanceof Error) {
    const iterableConvertedObject = getIterableObject(error);

    Sentry.captureException(new Error(`${message} -> ${JSON.stringify(iterableConvertedObject)}`));
  } else {
    Sentry.captureException(new Error(`${message} -> ${JSON.stringify(error)}`));
  }
};

const getIterableObject = (value: any) => {
  const newValue = Object.getOwnPropertyNames(value).reduce(
    (obj: any, propName) => {
      obj[propName] = value[propName];

      return obj;
    },
    { name: value.name },
  );

  return newValue;
};

// JSON.stringify() doesn't know how to serialize a BigInt
// https://github.com/GoogleChromeLabs/jsbi/issues/30
(BigInt.prototype as any).toJSON = function () {
  return this.toString();
};
