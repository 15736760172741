export interface UIState {
  snackbar: Snackbar;
  errorSnackbar: Snackbar;
}

export const UIInitialState: UIState = {
  snackbar: { isOpen: false, message: '' },
  errorSnackbar: { isOpen: false, message: '' },
};

interface Snackbar {
  isOpen: boolean;
  message: string;
}
