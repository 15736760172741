import { BrowserRouter } from 'react-router-dom';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import CssBaseline from '@mui/material/CssBaseline';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import UserSettings from 'Components/Atomic/UserSettings/UserSettings';
import Routes from './Routing/Routes';
import { persistor, store } from './Store/store';
import { theme } from './Styles/muiTheme';
import './App.css';

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider theme={theme}>
          <CssBaseline />

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <UserSettings>
              <BrowserRouter>
                <Routes />
              </BrowserRouter>
            </UserSettings>
          </LocalizationProvider>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
