import colors from 'Styles/colors';
import fonts from 'Styles/fonts';

const classes = {
  formContainer: {
    display: 'flex',
    flexDirection: { xs: 'column-reverse', md: 'row' },
    marginTop: { xs: '40px', sm: '56px', md: '80px' },
    boxShadow: '0 18px 35px -5px rgb(17 18 19 / 4%)',
    borderRadius: '32px',
  },

  formInfo: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    textAlign: 'left',
    backdropFilter: 'blur(6px)',
    padding: { xs: '32px', sm: '56px', md: '80px 72px' },
    backgroundColor: `${colors.LIGHT_GREY}80`,
    borderRadius: { xs: '0 0 32px 32px', md: '32px 0 0 32px' },
  },

  formInfoSubtitle: {
    marginTop: { xs: '8px', md: '16px' },
    marginBottom: '40px',
    color: colors.MEDIUM_GREY,
    fontSize: { xs: fonts.fontSize.s, md: fonts.fontSize.m },
  },

  iconRow: {
    marginTop: 'auto',
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
  },

  iconContainer: {
    backgroundColor: colors.WHITE,
    padding: '8px',
    borderRadius: '40px',
    lineHeight: 1,
  },

  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: '32px',
    flex: 1,
    padding: { xs: '32px', sm: '48px', md: '56px' },
    borderRadius: { xs: '32px 32px 0 0', md: '0 32px 32px 0' },
  },

  inputContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },

  input: {
    marginTop: '8px',
    width: '100%',
  },

  formButton: {
    height: '52px',
    borderRadius: '32px',
    fontSize: fonts.fontSize.m,
    marginTop: '8px',
  },
};

export default classes;
