export enum FirstLevelRoutes {
  LANDING_PAGE = '/',
  PRICING = 'pricing',
  CONTACT = 'contact',
  CREATE_ACCOUNT = 'create-account',
  SIGN_IN = 'sign-in',
  USER_DASHBOARD = 'my-dashboard',
  TOURNAMENT = 'tournament',
  MANAGE_TOURNAMENT = 'manage-tournament',
  TERMS_AND_CONDITIONS = 'terms-and-conditions',
  PRIVACY_POLICY = 'privacy-policy',
  COOKIE_POLICY = 'cookie-policy',
}

export enum SecondLevelRoutes {
  DASHBOARD = 'dashboard',
  REGISTERED_TEAMS = 'registered-teams',
  REGISTRATION = 'registration',
  GAMES_LIST = 'games-list',
  BRACKET = 'bracket',
  ADD_DELAY = 'add-delay',
  SEND_NOTIFICATION = 'send-notification',
  RECEIVED_MESSAGES = 'received-messages',
}
