import { useState } from 'react';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import Collapse from '@mui/material/Collapse';

import { FirstLevelRoutes } from 'Routing/route-paths';
import navbarClasses from '../styles';
import classes from './styles';
import { auth } from 'Services/firebase';

type Props = {
  isMobile: boolean;
};

const MyAccountNavigationItem = (props: Props) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isMobileCollapseOpen, setIsMobileCollapseOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMyTournamentsClick = () => {
    if (!location.pathname.includes(FirstLevelRoutes.USER_DASHBOARD)) {
      navigate(`../${FirstLevelRoutes.USER_DASHBOARD}`);
    }
    handleClose();
  };

  const signOut = () => {
    auth.signOut();
    handleClose();
  };

  const handleMobileDropdownClick = (event: any) => {
    event.stopPropagation();
    setIsMobileCollapseOpen(prev => !prev);
  };

  if (props.isMobile) {
    return (
      <Box>
        <Box sx={classes.mobileContainer} onClick={handleMobileDropdownClick}>
          <Typography sx={navbarClasses.mobileMenuText}>{t('navbar.my_account.label')}</Typography>

          <KeyboardArrowDownIcon sx={classes.mobileIcon} />
        </Box>

        <Collapse in={isMobileCollapseOpen} timeout="auto" unmountOnExit>
          <Box>
            <Typography sx={classes.mobileItem} onClick={handleMyTournamentsClick}>
              {t('navbar.my_account.my_tournaments')}
            </Typography>

            <Typography sx={classes.mobileItem} onClick={signOut}>
              {t('navbar.my_account.sign_out')}
            </Typography>
          </Box>
        </Collapse>
      </Box>
    );
  }

  return (
    <>
      <Box
        sx={classes.linkContainer}
        id="basic-button"
        aria-controls={Boolean(anchorEl) ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={Boolean(anchorEl) ? 'true' : undefined}
        onClick={handleMenu}>
        <Typography style={{ ...navbarClasses.desktopMenuText, ...classes.desktopMenuText }}>{t('navbar.my_account.label')}</Typography>

        <KeyboardArrowDownIcon sx={classes.icon} />
      </Box>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        MenuListProps={{ 'aria-labelledby': 'basic-button' }}
        onClose={handleClose}>
        <MenuItem sx={classes.item} onClick={handleMyTournamentsClick}>
          {t('navbar.my_account.my_tournaments')}
        </MenuItem>

        <MenuItem sx={classes.item} onClick={signOut}>
          {t('navbar.my_account.sign_out')}
        </MenuItem>
      </Menu>
    </>
  );
};

export default MyAccountNavigationItem;
