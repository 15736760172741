import { Navigate, Outlet } from 'react-router-dom';

import { useAppSelector } from 'Store/store';
import { FirstLevelRoutes } from './route-paths';

const ProtectedRoutes = () => {
  const userId = useAppSelector(state => state.user.user.id);

  return userId ? <Outlet /> : <Navigate to={FirstLevelRoutes.SIGN_IN} />;
};

export default ProtectedRoutes;
