export const getTournamentIdFromLocalStorage = () => {
  return localStorage.getItem('tournamentId');
};

export const saveTournamentIdInLocalStorage = (code: string) => {
  localStorage.setItem('tournamentId', code);
};

export const getShouldShowCookieConsent = () => {
  return localStorage.getItem('shouldShowCookiesConsent') ? false : true;
};

export const setShouldShowCookieConsent = () => {
  localStorage.setItem('shouldShowCookiesConsent', 'true');
};
