import { Reducer, useReducer } from 'react';

type ReducerActions<S> = {
  payload: Partial<S>;
};

const reducer = <S>(state: S, action: ReducerActions<S>) => {
  return { ...state, ...action.payload };
};

const updateState = <S>(state: Partial<S>): ReducerActions<S> => {
  return { payload: state };
};

const useCustomReducer = <S>(initialState: S) => {
  const [state, dispatch] = useReducer(reducer as Reducer<any, any>, initialState);

  const dispatchNewState = (newState: Partial<S>) => {
    dispatch(updateState(newState));
  };

  return [state, dispatchNewState] as [S, (newState: Partial<S>) => void];
};

export default useCustomReducer;
