import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import DoneIcon from '@mui/icons-material/Done';
import { useTranslation } from 'react-i18next';

import FrequentlyAskedQuestions from 'Components/Atomic/FrequentlyAskedQuestions/FrequentlyAskedQuestions';
import PricingPlans from 'Components/Atomic/PricingPlans/PricingPlans';
import landingClasses from '../LandingPage/styles';
import classes from './styles';

const PricingPage = () => {
  const { t } = useTranslation();

  const renderMainSection = () => {
    return (
      <>
        <Container sx={{ ...landingClasses.mainContainer, ...classes.mainContainer }}>
          <Typography variant="h1" sx={landingClasses.title}>
            <span style={landingClasses.titleSecondPart}>{t('pricing.title')}</span> <span>{t('pricing.title_second_part')}</span>
          </Typography>

          <Typography variant="h3" sx={{ ...landingClasses.subtitle, ...classes.subtitle }}>
            {t('pricing.subtitle')}
          </Typography>
        </Container>

        <Container sx={classes.pricingPlansContainer}>
          <PricingPlans />
        </Container>
      </>
    );
  };

  const renderFeatures = () => {
    return (
      <Container sx={{ ...landingClasses.section, ...landingClasses.centeredSection }}>
        <Typography variant="h1" sx={landingClasses.sectionTitle}>
          {t('pricing.features_section_title')}
        </Typography>

        <Grid container sx={classes.featuresContainer} rowSpacing={5} columnSpacing={3}>
          <Grid item xs={12} sm={6} md={4}>
            <Box sx={classes.featureTitleRow}>
              <Box sx={classes.iconContainer}>
                <DoneIcon />
              </Box>

              <Typography variant="h3">{t('pricing.feature_three_title')}</Typography>
            </Box>

            <Typography>{t('pricing.feature_three_text')}</Typography>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Box sx={classes.featureTitleRow}>
              <Box sx={classes.iconContainer}>
                <DoneIcon />
              </Box>

              <Typography variant="h3">{t('pricing.feature_one_title')}</Typography>
            </Box>

            <Typography>{t('pricing.feature_one_text')}</Typography>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Box sx={classes.featureTitleRow}>
              <Box sx={classes.iconContainer}>
                <DoneIcon />
              </Box>

              <Typography variant="h3">{t('pricing.feature_four_title')}</Typography>
            </Box>

            <Typography>{t('pricing.feature_four_text')}</Typography>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Box sx={classes.featureTitleRow}>
              <Box sx={classes.iconContainer}>
                <DoneIcon />
              </Box>

              <Typography variant="h3">{t('pricing.feature_five_title')}</Typography>
            </Box>

            <Typography>{t('pricing.feature_five_text')}</Typography>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Box sx={classes.featureTitleRow}>
              <Box sx={classes.iconContainer}>
                <DoneIcon />
              </Box>

              <Typography variant="h3">{t('pricing.feature_six_title')}</Typography>
            </Box>

            <Typography>{t('pricing.feature_six_text')}</Typography>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Box sx={classes.featureTitleRow}>
              <Box sx={classes.iconContainer}>
                <DoneIcon />
              </Box>

              <Typography variant="h3">{t('pricing.feature_two_title')}</Typography>
            </Box>

            <Typography>{t('pricing.feature_two_text')}</Typography>
          </Grid>
        </Grid>
      </Container>
    );
  };

  const renderFAQ = () => {
    return (
      <Container sx={{ ...landingClasses.section, ...landingClasses.centeredSection }}>
        <Box sx={{ ...landingClasses.chip, ...landingClasses.primaryChip }}>
          <Typography variant="h4">{t('faq.chip')}</Typography>
        </Box>

        <Typography variant="h1" sx={{ ...landingClasses.sectionTitle, ...landingClasses.sectionTitlePadding }}>
          {t('faq.title')}
        </Typography>

        <FrequentlyAskedQuestions />
      </Container>
    );
  };

  return (
    <>
      {renderMainSection()}

      {renderFeatures()}

      {renderFAQ()}
    </>
  );
};

export default PricingPage;
