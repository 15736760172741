export enum SignUpFields {
  NAME = 'name',
  EMAIL = 'email',
  PASSWORD = 'password',
}

export interface SignUpFieldsState {
  [SignUpFields.NAME]: { value: string; errorMessage: string; isValid: boolean };
  [SignUpFields.PASSWORD]: { value: string; errorMessage: string; isValid: boolean };
  [SignUpFields.EMAIL]: { value: string; errorMessage: string; isValid: boolean };
}

export const signUpFieldsInitialState: SignUpFieldsState = {
  [SignUpFields.NAME]: { value: '', errorMessage: '', isValid: false },
  [SignUpFields.PASSWORD]: { value: '', errorMessage: '', isValid: false },
  [SignUpFields.EMAIL]: { value: '', errorMessage: '', isValid: false },
};
