import { useState, KeyboardEvent } from 'react';

import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { signInWithEmailAndPassword } from 'firebase/auth';
import IconButton from '@mui/material/IconButton';
import { useNavigate } from 'react-router-dom';

import CustomTextField from 'Components/Atomic/CustomTextField/CustomTextField';
import ButtonWrapper from 'Components/Atomic/ButtonWrapper/ButtonWrapper';
import { auth } from 'Services/firebase';
import useFormReducer from 'Hooks/useFormReducer';
import { validationDictionary } from 'Utils/validationUtils';
import { logError } from 'Utils/loggingUtils';
import { FirstLevelRoutes } from 'Routing/route-paths';
import { SignInFields, signInFieldsInitialState } from './initialState';
import classes from 'Pages/SignUpPage/styles';

const SignInPage = () => {
  const [state, handleValueChange, getAreAllFieldsValid, validateInputs] = useFormReducer(signInFieldsInitialState);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const signIn = async () => {
    setErrorMessage('');
    setIsLoading(true);

    try {
      await signInWithEmailAndPassword(auth, state[SignInFields.EMAIL].value.trimEnd(), state[SignInFields.PASSWORD].value);

      navigate(`../${FirstLevelRoutes.USER_DASHBOARD}`);
    } catch (error) {
      logError('Create account', error);
      handleError(error);
      setIsLoading(false);
    }
  };

  const handleError = (error: any) => {
    if (error.code === 'auth/user-not-found' || error.code === 'auth/wrong-password') {
      setErrorMessage(t('sign_in.user_not_found'));
    } else {
      setErrorMessage(t('common_error_messages.generic'));
    }
  };

  const handleInvalidFields = () => {
    validateInputs([
      { name: SignInFields.EMAIL, constraints: validationDictionary.email },
      { name: SignInFields.PASSWORD, constraints: validationDictionary.password },
    ]);
  };

  const handlePasswordKeydown = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      getAreAllFieldsValid() ? signIn() : handleInvalidFields();
    }
  };

  return (
    <>
      <Container sx={classes.mainContainer}>
        <Typography variant="h1">{t('sign_in.title')}</Typography>

        <Typography variant="h3" sx={classes.subtitle}>
          {t('sign_in.subtitle')}
        </Typography>

        <Box sx={classes.rowContainer}>
          <CustomTextField
            label={t('sign_up.email_label')}
            placeholder={t('sign_up.email_placeholder')}
            type="email"
            required
            value={state[SignInFields.EMAIL].value}
            error={!!state[SignInFields.EMAIL].errorMessage}
            helperText={state[SignInFields.EMAIL].isValid ? undefined : state[SignInFields.EMAIL].errorMessage}
            onChange={e => handleValueChange(SignInFields.EMAIL, e.target.value.trimStart(), validationDictionary.email)}
          />
        </Box>

        <Box sx={classes.rowContainer}>
          <CustomTextField
            label={t('sign_up.password_label')}
            placeholder={t('sign_up.password_placeholder')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" sx={classes.passwordIcon}>
                  <IconButton onClick={() => setIsPasswordVisible(prev => !prev)} edge="end">
                    {isPasswordVisible ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            type={isPasswordVisible ? 'text' : 'password'}
            required
            value={state[SignInFields.PASSWORD].value}
            error={!!state[SignInFields.PASSWORD].errorMessage}
            helperText={state[SignInFields.PASSWORD].isValid ? undefined : state[SignInFields.PASSWORD].errorMessage}
            onKeyDown={handlePasswordKeydown}
            onChange={e => handleValueChange(SignInFields.PASSWORD, e.target.value, validationDictionary.name)}
          />
        </Box>

        <ButtonWrapper
          sx={classes.button}
          disabled={isLoading}
          isLoading={isLoading}
          onClick={() => (getAreAllFieldsValid() ? signIn() : handleInvalidFields())}>
          {t('sign_in.button')}
        </ButtonWrapper>

        <Typography variant="h4" sx={classes.signInLink} onClick={() => navigate(`../${FirstLevelRoutes.CREATE_ACCOUNT}`)}>
          {t('sign_in.create_account')}
        </Typography>

        {!!errorMessage && (
          <Typography variant="h4" marginTop="16px" color="error">
            {errorMessage}
          </Typography>
        )}
      </Container>
    </>
  );
};

export default SignInPage;
