import { PropsWithChildren, useEffect } from 'react';

import { onAuthStateChanged } from 'firebase/auth';

import { auth } from 'Services/firebase';
import { useAppDispatch } from 'Store/store';
import { resetUser } from 'Store/User/slice';
import { getUser } from 'Store/User/actions';

const UserSettings = (props: PropsWithChildren<{}>) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, user => {
      if (user) {
        dispatch(getUser(user.uid));
      } else {
        dispatch(resetUser());
      }
    });

    return () => unsubscribe();
  }, [dispatch]);

  return <>{props.children}</>;
};

export default UserSettings;
