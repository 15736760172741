const fonts = {
  fontFamily: {
    Regular: 'Raleway-Regular',
    SemiBold: 'Raleway-SemiBold',
    Bold: 'Raleway-Bold',
    Italic: 'Raleway-Italic',
  },

  fontSize: {
    xs: 12,
    s: 15,
    m: 18,
    lg: 24,
    xl: 32,
  },
};

export default fonts;
