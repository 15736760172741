import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import * as Sentry from '@sentry/react';

import tournamentReducer from './Tournament/slice';
import teamsReducer from './Teams/slice';
import userReducer from './User/slice';
import UIReducer from './UI/slice';
import { UserState } from './User/state';

const usersPersistConfig = { key: 'user', storage, whitelist: ['user'] };

const sentryReduxEnhancer = Sentry.createReduxEnhancer({});
export const store = configureStore({
  reducer: {
    tournament: tournamentReducer,
    teams: teamsReducer,
    user: persistReducer<UserState>(usersPersistConfig, userReducer),
    UI: UIReducer,
  },
  //TODO: revisit this. (because of Date types / Dayjs values)
  middleware: getDefaultMiddleware => getDefaultMiddleware({ serializableCheck: false }),
  enhancers: [sentryReduxEnhancer],
});
export const persistor = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
