import { createAsyncThunk } from '@reduxjs/toolkit';
import dayjs from 'dayjs';

import {
  getTournamentDetailsCall,
  updateRegistrationInfoCall,
  updateTextRegistrationFieldsCall,
  updateTournamentDetailsCall,
} from 'API/Firestore/TournamentCollection';
import { getTournamentCall, updateTournamentCall } from 'API/Firestore/TournamentsCollection';
import { getPrivateSettingsCall } from 'API/Firestore/TournamentPrivateCollection';
import { RootState } from 'Store/store';
import { openSnackbar } from 'Store/UI/slice';
import { saveTournamentIdInLocalStorage } from 'Services/LocalStorageService';
import { auth } from 'Services/firebase';
import { convertDateToUtc } from 'Utils/dateUtils';
import i18n from 'Translation/i18n';
import { TextRegistrationField } from 'Models/Tournament';
import { UpdateRegistrationInfoParams, UpdateTournamentActionParams } from './models';

export const getTournament = createAsyncThunk('tournament/getTournament', async (tournamentId: string) => {
  const [tournamentDetails, tournament, tournamentPrivateSettings] = await Promise.all([
    getTournamentDetailsCall(tournamentId),
    getTournamentCall(tournamentId),
    getPrivateSettingsCall(tournamentId),
  ]);

  if (!tournament || !tournamentDetails) {
    await auth.signOut();
    location.reload();
    return;
  }

  dayjs.tz.setDefault(tournament.timezone);
  saveTournamentIdInLocalStorage(tournamentId);

  return { tournament: { ...tournamentDetails, ...tournament }, tournamentPrivateSettings };
});

export const updateRegistrationInfo = createAsyncThunk(
  'tournament/updateRegistrationInfo',
  async ({ startDate, endDate, registrationTax }: UpdateRegistrationInfoParams, { getState, dispatch }) => {
    const tournamentId = (getState() as RootState).tournament.tournament.id;

    await updateRegistrationInfoCall(tournamentId, startDate, endDate, registrationTax);
    dispatch(openSnackbar(i18n.t('common.successful_update')));

    return {
      registrationTax,
      registrationStartDateUtc: convertDateToUtc(startDate.toDate()),
      registrationEndDateUtc: convertDateToUtc(endDate.toDate()),
    };
  },
);

export const updateTextRegistrationFields = createAsyncThunk(
  'tournament/updateRegistrationFields',
  async (textRegistrationFields: TextRegistrationField[], { getState, dispatch }) => {
    const tournamentId = (getState() as RootState).tournament.tournament.id;

    await updateTextRegistrationFieldsCall(tournamentId, textRegistrationFields);
    dispatch(openSnackbar(i18n.t('common.successful_update')));

    return { textRegistrationFields };
  },
);

export const updateTournament = createAsyncThunk(
  'tournament/updateTournament',
  async ({ tournament, tournamentDetails }: UpdateTournamentActionParams, { getState, dispatch }) => {
    const tournamentId = (getState() as RootState).tournament.tournament.id;

    await Promise.all([updateTournamentCall(tournamentId, tournament), updateTournamentDetailsCall(tournamentId, tournamentDetails)]);
    dayjs.tz.setDefault(tournament.timezone);
    dispatch(openSnackbar(i18n.t('common.successful_update')));

    return { ...tournamentDetails, ...tournament };
  },
);
