import colors from 'Styles/colors';
import fonts from 'Styles/fonts';

const classes = {
  label: {
    fontFamily: fonts.fontFamily.SemiBold,
    color: colors.MEDIUM_GREY,
  },

  sublabel: {
    color: colors.MEDIUM_GREY,
  },

  input: {
    marginTop: '4px',
    width: '100%',
    minWidth: { xs: '100%', sm: '300px' },
    maxWidth: '400px',
  },
};

export default classes;
