import { collection, query, orderBy, getDocs, deleteDoc, doc } from 'firebase/firestore';

import { firestore } from 'Services/firebase';
import { PendingTeam } from 'Models/PendingTeams';

export const getPendingTeamsCall = async (tournamentId: string): Promise<PendingTeam[]> => {
  const subColRef = collection(firestore, 'Tournament', tournamentId, 'PendingTeams');
  const q = query(subColRef, orderBy('addedDate', 'desc'));
  const response = await getDocs(q);

  const pendingTeams = response.docs.map(doc => {
    const data = doc.data() as PendingTeam;

    return { ...data, id: doc.id } as PendingTeam;
  });

  return pendingTeams;
};

export const deletePendingTeamCall = async (tournamentId: string, teamId: string) => {
  const docRef = doc(firestore, `Tournament/${tournamentId}/PendingTeams`, teamId);

  await deleteDoc(docRef);
};
