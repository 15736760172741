const classes = {
  container: {
    display: 'flex',
    flexDirection: { xs: 'column', sm: 'row  ' },
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: { xs: '16px', sm: '32px' },
    position: 'fixed',
    width: 'calc(100% - 48px)',
    bottom: '12px',
    left: '24px',
    right: '24px',
    padding: '12px 24px',
    backgroundColor: '#393A46',
    color: 'white',
    borderRadius: '12px',
  },

  leftContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
  },

  actionsContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
  },
};

export default classes;
