import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import EmailIcon from '@mui/icons-material/Email';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';

import FrequentlyAskedQuestions from 'Components/Atomic/FrequentlyAskedQuestions/FrequentlyAskedQuestions';
import CustomSnackbar from 'Components/Atomic/CustomSnackbar/CustomSnackbar';
import { validationDictionary } from 'Utils/validationUtils';
import useContactPage from './useContactPage';
import { ContactFields } from './initialState';
import landingClasses from '../LandingPage/styles';
import classes from './styles';

const ContactPage = () => {
  const { t } = useTranslation();
  const { state, isLoading, isSnackbarOpen, handleSendClick, handleValueChange, closeSnackbar } = useContactPage();

  const renderFormSection = () => {
    return (
      <Container sx={landingClasses.mainContainer}>
        <Typography variant="h1" sx={landingClasses.title}>
          {t('contact.title')}
        </Typography>

        <Typography variant="h3" sx={landingClasses.subtitle}>
          {t('contact.subtitle')}
        </Typography>

        <Box sx={classes.formContainer}>
          <Box sx={classes.formInfo}>
            <Typography variant="h1">{t('contact.form_title')}</Typography>

            <Typography sx={classes.formInfoSubtitle}>{t('contact.form_subtitle')}</Typography>

            <Box sx={classes.iconRow}>
              <Box sx={classes.iconContainer}>
                <EmailIcon />
              </Box>

              <Typography variant="h4">contact@blockoutapp.com</Typography>
            </Box>
          </Box>

          <Box sx={classes.form}>
            <Box sx={classes.inputContainer}>
              <Typography variant="h4">{t('contact.name_label')}</Typography>

              <TextField
                sx={classes.input}
                required
                placeholder={t('contact.name_placeholder')}
                value={state[ContactFields.NAME].value}
                error={!!state[ContactFields.NAME].errorMessage}
                helperText={state[ContactFields.NAME].isValid ? undefined : state[ContactFields.NAME].errorMessage}
                onChange={e => handleValueChange(ContactFields.NAME, e.target.value.trimStart(), validationDictionary.contact.name)}
              />
            </Box>

            <Box sx={classes.inputContainer}>
              <Typography variant="h4">{t('contact.email_label')}</Typography>

              <TextField
                sx={classes.input}
                required
                placeholder={t('contact.email_placeholder')}
                value={state[ContactFields.EMAIL].value}
                error={!!state[ContactFields.EMAIL].errorMessage}
                helperText={state[ContactFields.EMAIL].isValid ? undefined : state[ContactFields.EMAIL].errorMessage}
                onChange={e => handleValueChange(ContactFields.EMAIL, e.target.value.trimStart(), validationDictionary.email)}
              />
            </Box>

            <Box sx={classes.inputContainer}>
              <Typography variant="h4">{t('contact.message_label')}</Typography>

              <TextField
                sx={classes.input}
                required
                placeholder={t('contact.message_placeholder')}
                multiline
                minRows={4}
                maxRows={8}
                value={state[ContactFields.MESSAGE].value}
                error={!!state[ContactFields.MESSAGE].errorMessage}
                helperText={state[ContactFields.MESSAGE].isValid ? undefined : state[ContactFields.MESSAGE].errorMessage}
                onChange={e => handleValueChange(ContactFields.MESSAGE, e.target.value.trimStart(), validationDictionary.contact.message)}
              />
            </Box>

            <Button size="large" sx={classes.formButton} disabled={isLoading} onClick={handleSendClick}>
              {t('contact.send_button')}
            </Button>
          </Box>
        </Box>
      </Container>
    );
  };

  const renderFAQ = () => {
    return (
      <Container sx={{ ...landingClasses.section, ...landingClasses.centeredSection }}>
        <Box sx={{ ...landingClasses.chip, ...landingClasses.primaryChip }}>
          <Typography variant="h4">{t('faq.chip')}</Typography>
        </Box>

        <Typography variant="h1" sx={{ ...landingClasses.sectionTitle, ...landingClasses.sectionTitlePadding }}>
          {t('faq.title')}
        </Typography>

        <FrequentlyAskedQuestions />
      </Container>
    );
  };

  return (
    <>
      {renderFormSection()}

      {renderFAQ()}

      <CustomSnackbar isOpen={isSnackbarOpen} autoHideDuration={6000} message={t('contact.sent_successfully')} onClose={closeSnackbar} />
    </>
  );
};

export default ContactPage;
