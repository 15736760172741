import colors from 'Styles/colors';

const classes = {
  container: {
    display: 'flex',
    flexDirection: 'column',
  },

  subtitle: {
    marginBottom: '8px',
    color: colors.MEDIUM_GREY,
  },

  rowContainer: {
    marginTop: '24px',
  },

  datePickersRow: {
    display: 'flex',
    flexDirection: { xs: 'column', md: 'row' },
    marginTop: '8px',
    columnGap: '16px',
  },

  switchContainer: {
    '& span:last-of-type': {
      fontSize: 16,
    },
  },

  privatePublicLabelRow: {
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
  },

  addCategoryButton: {
    marginBottom: 1,
    marginLeft: 2,
  },

  removeIcon: {
    border: `1px solid ${colors.RED}`,
    borderRadius: '6px',
    fontSize: '22px',
  },

  categoryListItem: {
    display: 'list-item',
    pl: 0,
    ml: 2,
    paddingBlock: 0,
  },

  button: {
    marginTop: '32px',
    alignSelf: 'flex-start',
  },
};

export default classes;
