import { ActionReducerMapBuilder } from '@reduxjs/toolkit';

import { TournamentState } from './state';
import * as TournamentActions from './actions';
import { convertDateToUtc, getTournamentDays } from 'Utils/dateUtils';
import { Tournament, TournamentDetails } from 'Models/Tournament';

const extraReducers = (builder: ActionReducerMapBuilder<TournamentState>) => {
  getTournament(builder);
  updateTournament(builder);
  updateRegistrationInfo(builder);
  updateTextRegistrationFields(builder);
};

const getTournament = (builder: ActionReducerMapBuilder<TournamentState>) => {
  builder
    .addCase(TournamentActions.getTournament.pending, state => {
      state.isLoading = true;
    })
    .addCase(TournamentActions.getTournament.fulfilled, (state, action) => {
      const tournamentDays = getTournamentDays(action.payload!.tournament.startDateUtc, action.payload!.tournament.endDateUtc);

      state.tournament = action.payload!.tournament;
      state.tournamentDays = tournamentDays;
      state.privateSettings = action.payload!.tournamentPrivateSettings;
      state.isLoading = false;
    })
    .addCase(TournamentActions.getTournament.rejected, state => {
      state.isLoading = false;
    });
};

const updateTournament = (builder: ActionReducerMapBuilder<TournamentState>) => {
  builder
    .addCase(TournamentActions.updateTournament.pending, state => {
      state.isSavingTournament = true;
    })
    .addCase(TournamentActions.updateTournament.fulfilled, (state, action) => {
      const updatedTournament: Partial<Tournament & TournamentDetails> = {
        ...action.payload,
        startDateUtc: convertDateToUtc(action.payload.startDate!),
        endDateUtc: convertDateToUtc(action.payload.endDate!),
      };
      const tournamentDays = getTournamentDays(updatedTournament.startDateUtc!, updatedTournament.endDateUtc!);

      state.tournament = { ...state.tournament, ...updatedTournament };
      state.tournamentDays = tournamentDays;
      state.isSavingTournament = false;
    })
    .addCase(TournamentActions.updateTournament.rejected, state => {
      state.isSavingTournament = false;
    });
};

const updateRegistrationInfo = (builder: ActionReducerMapBuilder<TournamentState>) => {
  builder
    .addCase(TournamentActions.updateRegistrationInfo.pending, state => {
      state.isSaving = true;
    })
    .addCase(TournamentActions.updateRegistrationInfo.fulfilled, (state, action) => {
      state.tournament.registrationTax = action.payload.registrationTax;
      state.tournament.registrationStartDateUtc = action.payload.registrationStartDateUtc;
      state.tournament.registrationEndDateUtc = action.payload.registrationEndDateUtc;
      state.isSaving = false;
    })
    .addCase(TournamentActions.updateRegistrationInfo.rejected, state => {
      state.isSaving = false;
    });
};

const updateTextRegistrationFields = (builder: ActionReducerMapBuilder<TournamentState>) => {
  builder
    .addCase(TournamentActions.updateTextRegistrationFields.pending, state => {
      state.isSaving = true;
    })
    .addCase(TournamentActions.updateTextRegistrationFields.fulfilled, (state, action) => {
      state.tournament.textRegistrationFields = action.payload.textRegistrationFields;
      state.isSaving = false;
    })
    .addCase(TournamentActions.updateTextRegistrationFields.rejected, state => {
      state.isSaving = false;
    });
};

export default extraReducers;
