import { PropsWithChildren, useMemo } from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import useWindowSize from 'Hooks/useWindowSize';
import { drawerWidth, largeScreenContainerPadding } from 'Styles/constants';
import colors from 'Styles/colors';

type Props = {
  title: string;
  subtitle: string;
};

const TournamentSectionHeader = (props: PropsWithChildren<Props>) => {
  const [width] = useWindowSize();
  /* 350px is approximate of right area actions size */
  const leftAreaMaxWidth = useMemo(
    () =>
      props.children ? width - drawerWidth - 2 * largeScreenContainerPadding - 350 : width - drawerWidth - 2 * largeScreenContainerPadding,
    [width],
  );

  return (
    <Grid container display="flex" justifyContent="space-between" alignItems="center">
      <Grid item xs={12} lg="auto" sx={{ maxWidth: { lg: `${leftAreaMaxWidth}px`, xl: 'unset' } }}>
        <Typography variant="h2">{props.title}</Typography>

        <Typography mb={1} color={colors.MEDIUM_GREY}>
          {props.subtitle}
        </Typography>
      </Grid>

      <Grid item xs={12} lg display="flex" justifyContent={{ xs: 'flex-start', lg: 'flex-end' }} gap={1}>
        {props.children}
      </Grid>
    </Grid>
  );
};

export default TournamentSectionHeader;
