import { lazy } from 'react';

// -----------------------------------------------
// ---------------- Langing pages ----------------
// -----------------------------------------------

import LandingPage from 'Pages/LandingPage/LandingPage';
import ContactPage from 'Pages/Contact/ContactPage';
import PricingPage from 'Pages/Pricing/PricingPage';
import SignUpPage from 'Pages/SignUpPage/SignUpPage';
import SignInPage from 'Pages/SignIn/SignInPage';

const TournamentPage = lazy(() => import('Pages/Tournament/TournamentPage'));
const TermsAndConditionsPage = lazy(() => import('Pages/TermsAndConditions/TermsAndConditionsPage'));
const PrivacyPolicy = lazy(() => import('Pages/PrivacyPolicy/PrivacyPolicy'));
const CookiePolicy = lazy(() => import('Pages/CookiePolicy/CookiePolicy'));

// -----------------------------------------------
// ------------- Organize tournament -------------
// -----------------------------------------------

const UserDashboardPage = lazy(() => import('Pages/UserDashboard/UserDashboardPage'));
const ManageTournamentPage = lazy(() => import('Pages/ManageTournament/ManageTournamentPage'));
const Registration = lazy(() => import('Pages/OrganizeTournament/Registration/Registration'));
const RegisteredTeamsContainer = lazy(() => import('Pages/OrganizeTournament/RegisteredTeams/RegisteredTeamsContainer'));
const RegisteredGamesContainer = lazy(() => import('Pages/OrganizeTournament/RegisteredGames/RegisteredGamesContainer'));
const AddDelayContainer = lazy(() => import('Pages/OrganizeTournament/AddDelay/AddDelayContainer'));
const SendTournamentNotification = lazy(() => import('Pages/OrganizeTournament/SendTournamentNotification/SendTournamentNotification'));
const ReceivedMessages = lazy(() => import('Pages/OrganizeTournament/ReceivedMessages/ReceivedMessages'));
const BracketContainer = lazy(() => import('Pages/OrganizeTournament/Bracket/BracketContainer'));

export {
  LandingPage,
  ContactPage,
  PricingPage,
  SignInPage,
  SignUpPage,
  TournamentPage,
  TermsAndConditionsPage,
  PrivacyPolicy,
  CookiePolicy,
  UserDashboardPage,
  ManageTournamentPage,
  Registration,
  RegisteredTeamsContainer,
  RegisteredGamesContainer,
  AddDelayContainer,
  SendTournamentNotification,
  ReceivedMessages,
  BracketContainer,
};
