import { RegisteredTeam } from 'Models/RegisteredTeam';

export interface TeamsState {
  registeredTeams: { [key: string]: RegisteredTeam[] | undefined };
  teamIdsDisabledCheckbox: string[];
  isLoading: boolean;
  isUpdating: boolean;
  isDeleting: boolean;
  isAdding: boolean;
  isUpdatingPoints: boolean;
}

export const teamsInitialState: TeamsState = {
  registeredTeams: {},
  teamIdsDisabledCheckbox: [],
  isLoading: true,
  isUpdating: false,
  isDeleting: false,
  isAdding: false,
  isUpdatingPoints: false,
};
