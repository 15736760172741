import { useState } from 'react';

import Collapse from '@mui/material/Collapse';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import classes from './styles';

type Props = {
  question: string;
  answer: string;
};

const FrequentlyAskedQuestionItem = (props: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Box sx={classes.container} onClick={() => setIsOpen(prev => !prev)}>
      <Box sx={classes.questionRow}>
        <NavigateNextIcon sx={{ ...classes.icon, ...(isOpen ? classes.iconActive : {}) }} />

        <Typography variant="h3" textAlign="left">
          {props.question}
        </Typography>
      </Box>

      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <Typography sx={classes.answer}>{props.answer}</Typography>
      </Collapse>
    </Box>
  );
};

export default FrequentlyAskedQuestionItem;
