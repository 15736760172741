import { doc, getDoc, Timestamp, updateDoc } from 'firebase/firestore';
import { Dayjs } from 'dayjs';

import { GetTournamentDetails, TextRegistrationField, TournamentDetails, UpdateTournamentDetailsParams } from 'Models/Tournament';
import { firestore } from 'Services/firebase';
import { convertDateToUtc } from 'Utils/dateUtils';

export const getTournamentDetailsCall = async (tournamentId: string) => {
  const docRef = doc(firestore, 'Tournament', tournamentId);
  const docSnap = await getDoc(docRef);
  const data = docSnap.data() as GetTournamentDetails;

  if (!data) {
    return undefined;
  }

  const registrationStartDateUtc = convertDateToUtc(
    new Timestamp(data.registrationStartDate.seconds, data.registrationStartDate.nanoseconds).toDate(),
  );
  const registrationEndDateUtc = convertDateToUtc(
    new Timestamp(data.registrationEndDate.seconds, data.registrationEndDate.nanoseconds).toDate(),
  );

  return {
    ...data,
    id: docSnap.id,
    registrationStartDateUtc,
    registrationEndDateUtc,
    registrationStartDate: undefined,
    registrationEndDate: undefined,
  } as TournamentDetails;
};

export const updateTournamentDetailsCall = async (tournamentId: string, tournamentDetails: UpdateTournamentDetailsParams) => {
  const docRef = doc(firestore, 'Tournament', tournamentId);

  await updateDoc(docRef, tournamentDetails);
};

export const updateRegistrationInfoCall = async (tournamentId: string, startDate: Dayjs, endDate: Dayjs, registrationTax: string) => {
  const docRef = doc(firestore, 'Tournament', tournamentId);

  await updateDoc(docRef, {
    registrationTax,
    registrationStartDate: Timestamp.fromDate(startDate.toDate()),
    registrationEndDate: Timestamp.fromDate(endDate.toDate()),
  });
};

export const updateTextRegistrationFieldsCall = async (tournamentId: string, textRegistrationFields: TextRegistrationField[]) => {
  const docRef = doc(firestore, 'Tournament', tournamentId);

  await updateDoc(docRef, { textRegistrationFields });
};
