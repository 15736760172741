import Button, { ButtonProps } from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

export type ButtonWrapperProps = {
  isLoading?: boolean;
} & ButtonProps;

const ButtonWrapper = (props: ButtonWrapperProps) => {
  const { isLoading, children, ...buttonProps } = props;

  return (
    <Button {...buttonProps}>
      {children}

      {isLoading && <CircularProgress size={18} thickness={5} sx={{ marginLeft: '8px' }} />}
    </Button>
  );
};

export default ButtonWrapper;
