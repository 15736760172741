import { User } from 'Models/User';

export interface UserState {
  user: User;
  isLoading: boolean;
}

export const userInitialState: UserState = {
  user: { id: '', name: '', email: '' },
  isLoading: true,
};
