import { CaseReducer, PayloadAction } from '@reduxjs/toolkit';

import { UIState } from './state';

export const openSnackbarReducer: CaseReducer<UIState, PayloadAction<string>> = (state, action) => {
  state.snackbar.message = action.payload;
  state.snackbar.isOpen = true;
};

export const closeSnackbarReducer: CaseReducer<UIState, PayloadAction<undefined>> = state => {
  state.snackbar.isOpen = false;
};

export const openErrorSnackbarReducer: CaseReducer<UIState, PayloadAction<string>> = (state, action) => {
  state.errorSnackbar.message = action.payload;
  state.errorSnackbar.isOpen = true;
};

export const closeErrorSnackbarReducer: CaseReducer<UIState, PayloadAction<undefined>> = state => {
  state.errorSnackbar.isOpen = false;
};
