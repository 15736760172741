import { createSlice } from '@reduxjs/toolkit';

import { UIInitialState } from './state';
import { openSnackbarReducer, closeSnackbarReducer, closeErrorSnackbarReducer, openErrorSnackbarReducer } from './reducers';

const UISlice = createSlice({
  name: 'UI',
  initialState: UIInitialState,
  reducers: {
    openSnackbar: openSnackbarReducer,
    closeSnackbar: closeSnackbarReducer,
    openErrorSnackbar: openErrorSnackbarReducer,
    closeErrorSnackbar: closeErrorSnackbarReducer,
  },
});

export default UISlice.reducer;

export const { openSnackbar, closeSnackbar, openErrorSnackbar, closeErrorSnackbar } = UISlice.actions;
