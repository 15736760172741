import createTheme from '@mui/material/styles/createTheme';

import colors from './colors';
import fonts from 'Styles/fonts';

const breakpointsValues = { xs: 0, sm: 600, md: 960, lg: 1280, xl: 1400 };

export const theme = createTheme({
  spacing: 8,

  breakpoints: {
    values: {
      xs: breakpointsValues.xs,
      sm: breakpointsValues.sm,
      md: breakpointsValues.md,
      lg: breakpointsValues.lg,
      xl: breakpointsValues.xl,
    },
  },

  palette: {
    background: {
      default: colors.WHITE,
    },
    secondary: {
      main: colors.PRIMARY,
      contrastText: colors.WHITE,
      dark: '#02acb3',
      light: '#26e2e9',
    },
    primary: {
      main: colors.PRIMARY,
      contrastText: colors.WHITE,
      dark: '#02acb3',
      light: '#26e2e9',
    },
    warning: {
      main: colors.WARNING,
    },
  },

  components: {
    MuiContainer: {
      styleOverrides: {
        root: {
          '@media (max-width: 600px)': {
            paddingLeft: '24px',
            paddingRight: '24px',
          },
        },
      },
    },
    MuiButton: {
      defaultProps: {
        variant: 'contained',
      },

      styleOverrides: {
        root: {
          fontFamily: fonts.fontFamily.Bold,
          height: 40,
          minWidth: 130,
          lineHeight: 1,
          textTransform: 'unset',
        },

        contained: {
          boxShadow: 'rgba(0, 0, 0, 0.04) 0px 3px 5px',
        },

        outlined: {
          boxShadow: 'rgba(0, 0, 0, 0.04) 0px 1px 3px',
        },

        sizeSmall: {
          height: 34,
        },

        text: {
          color: 'black',
        },
      },
    },

    MuiInputBase: {
      styleOverrides: {
        root: {
          borderRadius: '8px !important',
          boxShadow: '0px 0px 1px 0px #eeeeee',
        },

        input: {
          padding: '14.5px 12px !important',
        },

        inputSizeSmall: {
          padding: '8.5px 12px !important',
        },

        inputMultiline: {
          padding: '0 !important',
        },
      },
    },

    MuiAutocomplete: {
      styleOverrides: {
        input: {
          padding: '5.5px 4px 5.5px 6px !important',
        },
      },
    },

    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderColor: colors.BORDER,
        },
      },
    },

    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: colors.BORDER,
        },
      },
    },

    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: `0 3px 5px ${colors.BORDER}`,
        },
      },
    },

    MuiTable: {
      styleOverrides: {
        root: {
          maxWidth: '1200px',
        },
      },
    },

    MuiTableHead: {
      styleOverrides: {
        root: {
          '> tr > th': {
            textTransform: 'uppercase',
            color: colors.MEDIUM_GREY,
            paddingLeft: 0,
          },
        },
      },
    },

    MuiTableCell: {
      defaultProps: {
        align: 'left',
      },

      styleOverrides: {
        root: {
          paddingLeft: 0,
        },
      },
    },

    MuiStep: {
      styleOverrides: {
        root: {
          paddingLeft: '16px',
          paddingRight: '16px',

          '&:first-of-type': {
            paddingLeft: 0,
          },
        },
      },
    },

    MuiStepIcon: {
      styleOverrides: {
        text: {
          fontFamily: fonts.fontFamily.SemiBold,
        },
      },
    },

    MuiStepLabel: {
      styleOverrides: {
        label: {
          '&.Mui-active': {
            fontFamily: fonts.fontFamily.Bold,
          },
          '&.Mui-completed': {
            fontFamily: fonts.fontFamily.Bold,
          },
        },
      },
    },
  },

  typography: {
    fontFamily: fonts.fontFamily.Regular,
    fontWeightRegular: '500',

    h1: {
      fontFamily: fonts.fontFamily.Bold,
      fontSize: fonts.fontSize.lg,

      [`@media (min-width: ${breakpointsValues.sm}px)`]: {
        fontSize: fonts.fontSize.xl,
      },
    },

    h2: {
      fontFamily: fonts.fontFamily.Bold,
      fontSize: 22,

      [`@media (min-width: ${breakpointsValues.lg}px)`]: {
        fontSize: fonts.fontSize.lg,
      },
    },

    h3: {
      fontFamily: fonts.fontFamily.Bold,
      fontSize: fonts.fontSize.s + 1,

      [`@media (min-width: ${breakpointsValues.md}px)`]: {
        fontSize: fonts.fontSize.m,
      },
    },

    h4: {
      fontFamily: fonts.fontFamily.Bold,
      fontSize: fonts.fontSize.s,
    },

    h5: {
      fontFamily: fonts.fontFamily.Bold,
      fontSize: 12,
    },

    h6: {
      fontFamily: fonts.fontFamily.Regular,
      fontSize: 12,
    },

    body1: {
      fontSize: fonts.fontSize.s,
    },
  },
});
