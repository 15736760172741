export enum ContactFields {
  NAME = 'name',
  EMAIL = 'email',
  MESSAGE = 'message',
}

export interface ContactState {
  [ContactFields.NAME]: { value: string; errorMessage: string; isValid: boolean };
  [ContactFields.EMAIL]: { value: string; errorMessage: string; isValid: boolean };
  [ContactFields.MESSAGE]: { value: string; errorMessage: string; isValid: boolean };
}

export const contactInitialState: ContactState = {
  [ContactFields.NAME]: { value: '', errorMessage: '', isValid: false },
  [ContactFields.EMAIL]: { value: '', errorMessage: '', isValid: false },
  [ContactFields.MESSAGE]: { value: '', errorMessage: '', isValid: false },
};
