import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import RemoveIcon from '@mui/icons-material/Remove';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';

import TournamentSectionHeader from 'Components/Atomic/TournamentSectionHeader/TournamentSectionHeader';
import AutocompletePlaceSearch from 'Components/Atomic/AutocompletePlaceSearch/AutocompletePlaceSearch';
import CustomTextField from 'Components/Atomic/CustomTextField/CustomTextField';
import DateRange from 'Components/Atomic/DateRange/DateRange';
import CustomTooltip from 'Components/Atomic/CustomTooltip/CustomTooltip';
import { useAppSelector } from 'Store/store';
import { validationDictionary } from 'Utils/validationUtils';
import { DashboardTextFields } from './initialState';
import useDashboard from './useDashboard';
import customTextFieldClasses from 'Components/Atomic/CustomTextField/styles';
import classes from './styles';

const Dashboard = () => {
  const { t } = useTranslation();
  const { textFieldsState, state, isOnFreePlan, ...functions } = useDashboard();
  const isSaving = useAppSelector(state => state.tournament.isSavingTournament);
  const tournamentNameHelperText = textFieldsState[DashboardTextFields.TOURNAMENT_NAME].isValid
    ? undefined
    : textFieldsState[DashboardTextFields.TOURNAMENT_NAME].errorMessage;
  const tournamentDescriptionHelperText = textFieldsState[DashboardTextFields.DESCRIPTION].isValid
    ? undefined
    : textFieldsState[DashboardTextFields.DESCRIPTION].errorMessage;
  const tournamentOrganizerHelperText = textFieldsState[DashboardTextFields.ORGANIZER].isValid
    ? undefined
    : textFieldsState[DashboardTextFields.ORGANIZER].errorMessage;

  return (
    <Box sx={classes.container}>
      <TournamentSectionHeader title={t('dashboard.title')} subtitle={t('dashboard.subtitle')} />

      <Grid container gap={3} sx={classes.rowContainer}>
        <Grid item xs={12} sm="auto">
          <CustomTextField
            label={t('dashboard.form.tournament_name')}
            placeholder={t('dashboard.form.tournament_name_placeholder')}
            required
            value={textFieldsState[DashboardTextFields.TOURNAMENT_NAME].value}
            error={!!textFieldsState[DashboardTextFields.TOURNAMENT_NAME].errorMessage}
            helperText={tournamentNameHelperText}
            onChange={e =>
              functions.handleValueChange(
                DashboardTextFields.TOURNAMENT_NAME,
                e.target.value.trimStart(),
                validationDictionary.tournamentName,
              )
            }
          />
        </Grid>

        <Grid item xs={12} sm="auto">
          <CustomTextField
            label={t('dashboard.form.organizer')}
            placeholder={t('dashboard.form.organizer_placeholder')}
            required
            value={textFieldsState[DashboardTextFields.ORGANIZER].value}
            error={!!textFieldsState[DashboardTextFields.ORGANIZER].errorMessage}
            helperText={tournamentOrganizerHelperText}
            onChange={e =>
              functions.handleValueChange(DashboardTextFields.ORGANIZER, e.target.value.trimStart(), validationDictionary.organizer)
            }
          />
        </Grid>
      </Grid>

      <Box sx={classes.rowContainer}>
        <AutocompletePlaceSearch
          value={state.location}
          errorMessage={state.locationErrorMessage}
          onValueChange={functions.handleLocationChange}
          onTimezoneChange={functions.handleTimezoneChange}
        />
      </Box>

      <DateRange
        startDate={state.startDate}
        endDate={state.endDate}
        dateErrorMessage={state.dateErrorMessage}
        onStartDateChange={functions.handleStartDateChange}
        onEndDateChange={functions.handleEndDateChange}
      />

      <Box sx={classes.rowContainer}>
        <Box sx={classes.privatePublicLabelRow}>
          <Typography sx={customTextFieldClasses.label}>{t('dashboard.form.private_or_public_label')}</Typography>

          <CustomTooltip text={t('dashboard.form.private_or_public_tooltip')} />
        </Box>

        <FormControlLabel
          sx={classes.switchContainer}
          control={<Switch disabled={isOnFreePlan} checked={state.isPrivate} onChange={functions.handlePrivacyChange} />}
          label={t('dashboard.form.private_or_public_switch_text')}
        />
      </Box>

      <CustomTextField
        inputContainer={classes.rowContainer}
        label={t('dashboard.form.description')}
        placeholder={t('dashboard.form.description_placeholder')}
        required
        multiline
        minRows={3}
        value={textFieldsState[DashboardTextFields.DESCRIPTION].value}
        error={!!textFieldsState[DashboardTextFields.DESCRIPTION].errorMessage}
        helperText={tournamentDescriptionHelperText}
        onChange={e =>
          functions.handleValueChange(
            DashboardTextFields.DESCRIPTION,
            e.target.value.trimStart(),
            validationDictionary.tournamentDescription,
          )
        }
      />

      <Typography mt={3} sx={customTextFieldClasses.label}>
        {t('dashboard.form.categories')}
      </Typography>

      <Box display="flex">
        <TextField
          sx={{ ...customTextFieldClasses.input, minWidth: '150px', maxWidth: '300px' }}
          placeholder={t('dashboard.form.categories_placeholder')}
          required
          value={state.categoryInput}
          error={!!state.categoryErrorMessage}
          helperText={state.categoryErrorMessage}
          onChange={e => functions.updateCategoryInput(e.target.value.trimStart())}
          onKeyDown={event => (event.key === 'Enter' ? functions.addCategory() : undefined)}
        />

        <Button
          variant="outlined"
          size="small"
          disabled={!state.categoryInput}
          sx={{ mt: 1.5, ml: 2, px: 3, minWidth: 'unset' }}
          onClick={functions.addCategory}>
          {t('common.add')}
        </Button>
      </Box>

      <List sx={{ listStyleType: 'disc', mt: 1 }}>
        {state.categories.map(category => (
          <ListItem key={category} sx={classes.categoryListItem}>
            {category}

            <IconButton sx={{ ml: 1.5 }} onClick={() => functions.removeCategory(category)}>
              <RemoveIcon color="error" sx={classes.removeIcon} />
            </IconButton>
          </ListItem>
        ))}
      </List>

      <Button sx={classes.button} disabled={isSaving} onClick={functions.save}>
        {t('common.save')}
      </Button>
    </Box>
  );
};

export default Dashboard;
