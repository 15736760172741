import dayjs, { Dayjs } from 'dayjs';

export enum DashboardTextFields {
  TOURNAMENT_NAME = 'tournamentName',
  ORGANIZER = 'organizer',
  DESCRIPTION = 'description',
}

export interface DashboardTextFieldsState {
  [DashboardTextFields.TOURNAMENT_NAME]: { value: string; errorMessage: string; isValid: boolean };
  [DashboardTextFields.ORGANIZER]: { value: string; errorMessage: string; isValid: boolean };
  [DashboardTextFields.DESCRIPTION]: { value: string; errorMessage: string; isValid: boolean };
}

interface DashboardState {
  startDate: Dayjs;
  endDate: Dayjs;
  dateErrorMessage: string;
  location: google.maps.places.AutocompletePrediction | null;
  locationErrorMessage: string;
  selectedCountry: string;
  selectedTimezone: string;
  categories: string[];
  categoryInput: string;
  categoryErrorMessage: string;
  isPrivate: boolean;
  isLoading: boolean;
}

export const dashboardTextFieldsInitialState: DashboardTextFieldsState = {
  [DashboardTextFields.TOURNAMENT_NAME]: { value: '', errorMessage: '', isValid: false },
  [DashboardTextFields.ORGANIZER]: { value: '', errorMessage: '', isValid: false },
  [DashboardTextFields.DESCRIPTION]: { value: '', errorMessage: '', isValid: false },
};

export const dashboardInitialState: DashboardState = {
  startDate: dayjs(),
  endDate: dayjs(),
  dateErrorMessage: '',
  location: null,
  locationErrorMessage: '',
  selectedCountry: '',
  selectedTimezone: '',
  categories: [],
  categoryInput: '',
  categoryErrorMessage: '',
  isPrivate: true,
  isLoading: true,
};
