import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

type Props = {
  isOpen: boolean;
  message: string;
  autoHideDuration?: number;
  severity?: 'success' | 'error' | 'warning' | 'info';
  onClose: () => void;
};

const CustomSnackbar = (props: Props) => {
  return (
    <Snackbar
      open={props.isOpen}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      autoHideDuration={props.autoHideDuration ?? 4000}
      onClose={(_, reason) => (reason === 'clickaway' ? undefined : props.onClose())}>
      <Alert onClose={props.onClose} severity={props.severity ?? 'success'} sx={{ width: '100%' }}>
        {props.message}
      </Alert>
    </Snackbar>
  );
};

export default CustomSnackbar;
