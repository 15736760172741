import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';

import colors from 'Styles/colors';

type Props = {
  text: string;
};

const CustomTooltip = (props: Props) => {
  return (
    <Tooltip disableFocusListener title={props.text}>
      <InfoIcon sx={{ color: colors.MEDIUM_GREY }} fontSize="small" />
    </Tooltip>
  );
};

export default CustomTooltip;
