export enum PricingPlansIds {
  FREE = 1,
  MEDIUM = 2,
  LARGE = 3,
  X_LARGE = 4,
}

export enum PlanUpgradeIds {
  MEDIUM_TO_LARGE = 5,
  MEDIUM_TO_X_LARGE = 6,
  LARGE_TO_X_LARGE = 7,
}

export interface PricingPlan {
  id: PricingPlansIds;
  currentPrice: number;
  oldPrice: number;
}

export const pricingPlansOptions: PricingPlan[] = [
  { id: PricingPlansIds.FREE, currentPrice: 0, oldPrice: 0 },
  { id: PricingPlansIds.MEDIUM, currentPrice: 14.99, oldPrice: 25 },
  { id: PricingPlansIds.LARGE, currentPrice: 29.99, oldPrice: 50 },
  { id: PricingPlansIds.X_LARGE, currentPrice: 56.99, oldPrice: 95 },
];
