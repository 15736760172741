import dayjs, { Dayjs } from 'dayjs';
import {
  collection,
  query,
  where,
  orderBy,
  doc,
  getDocs,
  Timestamp,
  addDoc,
  deleteDoc,
  updateDoc,
  limit,
  writeBatch,
} from 'firebase/firestore';

import { firestore } from 'Services/firebase';
import { AddGamePayload, Game, GetGameData } from 'Models/Game';
import { convertDateToUtc } from 'Utils/dateUtils';

export const getAllBracketGamesCall = async (tournamentId: string, category: string, numberOfGames: number): Promise<Game[]> => {
  const subColRef = collection(firestore, 'Tournament', tournamentId, 'Games');
  const q = query(subColRef, where('category', '==', category), where('number', '>', 0), where('number', '<', numberOfGames + 1));
  const response = await getDocs(q);

  return response.docs.map(doc => {
    const data = doc.data() as GetGameData;

    return { ...data, id: doc.id, timeUtc: convertDateToUtc(new Timestamp(data.time.seconds, data.time.nanoseconds).toDate()) } as Game;
  });
};

export const getAreGamesAddedCall = async (tournamentId: string, category: string): Promise<boolean> => {
  const subColRef = collection(firestore, 'Tournament', tournamentId, 'Games');
  const q = query(subColRef, where('category', '==', category), limit(1));
  const response = await getDocs(q);

  return response.docs.length > 0;
};

export const getDaysGamesCall = async (tournamentId: string, date: Dayjs, category: string): Promise<Game[]> => {
  const subColRef = collection(firestore, 'Tournament', tournamentId, 'Games');
  const startDate = date.startOf('day').toDate();
  const endDate = date.endOf('day').toDate();
  const q = query(
    subColRef,
    where('category', '==', category),
    where('time', '>=', Timestamp.fromDate(startDate)),
    where('time', '<=', Timestamp.fromDate(endDate)),
    orderBy('time'),
  );
  const response = await getDocs(q);

  return response.docs.map(doc => {
    const data = doc.data() as GetGameData;

    return { ...data, id: doc.id, timeUtc: convertDateToUtc(new Timestamp(data.time.seconds, data.time.nanoseconds).toDate()) } as Game;
  });
};

export const getRemainingGamesFromDayCall = async (tournamentId: string, startDate: Dayjs, endDate?: Dayjs): Promise<Game[]> => {
  const subColRef = collection(firestore, 'Tournament', tournamentId, 'Games');
  const finalEndDate = endDate ? endDate.toDate() : startDate.endOf('day').toDate();
  const q = query(subColRef, where('time', '>=', startDate.toDate()), where('time', '<=', finalEndDate));
  const response = await getDocs(q);

  return response.docs.map(doc => {
    const data = doc.data() as GetGameData;

    return { ...data, id: doc.id, timeUtc: convertDateToUtc(new Timestamp(data.time.seconds, data.time.nanoseconds).toDate()) } as Game;
  });
};

export const getGameCall = async (tournamentId: string, category: string, gameNumber: number) => {
  const tournamentCollection = collection(firestore, `Tournament/${tournamentId}/Games`);
  const q = query(tournamentCollection, where('category', '==', category), where('number', '==', gameNumber));
  const response = await getDocs(q);
  const games = response.docs.map(doc => {
    const data = doc.data() as GetGameData;

    return { ...data, id: doc.id, timeUtc: convertDateToUtc(new Timestamp(data.time.seconds, data.time.nanoseconds).toDate()) } as Game;
  });

  return games ? games[0] : undefined;
};

export const addGameCall = async (tournamentId: string, gamePayload: AddGamePayload) => {
  const docRef = await addDoc(collection(firestore, `Tournament/${tournamentId}/Games`), gamePayload);

  return docRef.id;
};

export const deleteGameCall = async (tournamentId: string, gameId: string) => {
  const docRef = doc(firestore, `Tournament/${tournamentId}/Games`, gameId);

  await deleteDoc(docRef);
};

export const updateGameCall = async (tournamentId: string, game: Game) => {
  const { id, timeUtc, ...updatedGame } = game;
  const docRef = doc(firestore, `Tournament/${tournamentId}/Games`, id);
  const updatedGameWithTime = { ...updatedGame, time: dayjs(timeUtc).toDate() };

  await updateDoc(docRef, updatedGameWithTime);
};

export const updateGamesTimeCall = async (tournamentId: string, games: Game[]) => {
  let gamesCall: Promise<void>[] = [];

  games.forEach(game => {
    const docRef = doc(firestore, `Tournament/${tournamentId}/Games`, game.id);
    const time = dayjs(game.timeUtc).toDate(); // No need to convert to tournament's timezone since it represents solely a point in time

    gamesCall.push(updateDoc(docRef, { time }));
  });

  await Promise.all(gamesCall);
};

export const addBracketGamesCall = async (tournamentId: string, games: AddGamePayload[]) => {
  const batch = writeBatch(firestore);

  games.forEach(game => {
    const gameDoc = doc(collection(firestore, `Tournament/${tournamentId}/Games`));

    batch.set(gameDoc, game);
  });

  await batch.commit();
};

export const deleteAllCategoryBracketGamesCall = async (tournamentId: string, bracketGames: Game[]) => {
  const batch = writeBatch(firestore);

  bracketGames.forEach(game => {
    const gameDoc = doc(firestore, `Tournament/${tournamentId}/Games`, game.id);

    batch.delete(gameDoc);
  });

  await batch.commit();
};

export const updateGamesByTeamNameCall = async (tournamentId: string, teamId: string, newTeamName: string) => {
  const subColRef = collection(firestore, 'Tournament', tournamentId, 'Games');
  const queryOne = query(subColRef, where('teamOne.id', '==', teamId));
  const queryTwo = query(subColRef, where('teamTwo.id', '==', teamId));
  const [responseOne, responseTwo] = await Promise.all([getDocs(queryOne), getDocs(queryTwo)]);
  const games = [...responseOne.docs, ...responseTwo.docs].map(doc => {
    const data = doc.data() as GetGameData;

    return { ...data, id: doc.id };
  });
  let gamesCall: Promise<void>[] = [];

  games.forEach(game => {
    const docRef = doc(firestore, `Tournament/${tournamentId}/Games`, game.id);
    const teamOne = game.teamOne.id === teamId ? { id: teamId, name: newTeamName } : game.teamOne;
    const teamTwo = game.teamTwo.id === teamId ? { id: teamId, name: newTeamName } : game.teamTwo;
    const payload: Partial<Game> = { teamOne, teamTwo };

    gamesCall.push(updateDoc(docRef, payload));
  });

  await Promise.all(gamesCall);
};
