import { CaseReducer, PayloadAction } from '@reduxjs/toolkit';

import { Tournament, TournamentDetails } from 'Models/Tournament';
import { getTournamentDays } from 'Utils/dateUtils';
import { TournamentState } from './state';

export const setTournamentReducer: CaseReducer<TournamentState, PayloadAction<Tournament & TournamentDetails>> = (state, action) => {
  const tournamentDays = getTournamentDays(action.payload.startDateUtc, action.payload.endDateUtc);

  state.tournamentDays = tournamentDays;
  state.tournament = action.payload;
};
